.drawpad, .type-signature {
	position: relative;

  .drawpad--tools {
    position: absolute;
    display: flex;
    width: 100%;
    margin-top: 5px;
    padding: 0 10px;
    .iti-mobile & {
      position: static;
      flex-wrap: wrap;
      padding: 0;
    }
  }
  h4 {
    margin-top: $b/4;
    color: setting(colors, font-light)!important;
    width: 100%;
    order: 1;
    .iti-mobile & {
      margin-bottom: 1em;
      color: #111111 !important;
    }
  }

	&__instructions {
		top: 0;
		right: 20%;
		bottom: 0;
		left: 20%;
		color: setting(colors, font-light);
	}

	&__canvas {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: block;
    border: 1px dashed setting(colors, border-dark);
    border-radius: setting(border-radius);
    background: #fff;
  }

  .passthrough {
    pointer-events: none;
  }

  &__container {
    height: 100%;
    width: 100%;
  }
  .color-switcher--wrap {
    flex: 1 0 auto;
    order: 0;
    .iti-mobile & {
      order: 1;
    }
  }
}
.drawpad__canvas {
  cursor: crosshair;
}

.signature-text-input {
  position: relative;
  margin-bottom: $b/2;
  .input--text.ng-valid.ng-dirty {
    border-color: inherit;
  }
}

.canvas_inline_button {
  flex: 1 0 auto;
  order: 2;
  text-align: right;
  .button {
    margin-top: 0 !important;
  }
}

.type-signature {
  height: 100px;


  loading {
    position: absolute;
    top: 28%;
    width: 100%;
    text-align: center;
  }

}

.color-switcher {
  input[type="radio"] {
      display:none;

    & + label span {
      border-radius: 50%;
      display:inline-block;
      width:19px;
      height:19px;
      margin-right: $b/4;
      vertical-align:middle;
      cursor:pointer;
      opacity: 0.5;
      transition: all .2s ease-out;
    }

    &:checked + label span {
      transform: scale(1.1);
      opacity: 1;
    }

  }
}

.type-signature input[type=radio] + span {
  top: 0.4em;
  left: 0.4em;
}

.signature-text-preview {
  .radio--image {
    margin-top: 0;
  }

  .color-switcher {
    margin-bottom: 1em;
  }
}
