$forms: (
	border-radius: setting(border-radius),
	border-color: setting(colors, border),
	border-color-hover: darken(setting(colors, border), 10),
	border-color-focus: darken(setting(colors, border), 20),
	placeholder-color: setting(colors, font-light),
	checkbox-width: $b*0.75,
	checkbox-height: $b*0.75,
	radio-width: $b*0.75,
	radio-height: $b*0.75
);

/*
Forms

Markup:
<form class="form">
	<fieldset>
		<div class="form__item">
			<label for="input--name">Name</label>
			<input type="text" class="input--text" id="input--name" placeholder="Enter your name">
		</div>
		<div class="form__item">
			<label for="input--username">Username</label>
			<input type="text" class="input--text" id="input--username" placeholder="John Doe" disabled>
		</div>
		<div class="form__item">
			<div class="checkbox">
				<input type="checkbox" value="agree" id="input--agree"><span></span><label for="input--agree">I agree</label>
			</div>
		</div>
		<div class="form__item">
			<label>Gender</label>
			<div class="radio">
				<input type="radio" value="Male" checked="checked" name="gender" id="input--male"><span></span><label for="input--male">Male</label>
			</div>
			<div class="radio">
				<input type="radio" value="Female" name="gender" id="input--female"><span></span><label for="input--female">Female</label>
			</div>
		</div>
		<div class="form__item">
			<label for="input--country">Country</label>
			<select class="select" name="select-lorem" id="input--country">
				<optgroup label="Benelux">
					<option value="NL">The Netherlands</option>
					<option value="BE">Belgium</option>
				</optgroup>
				<option value="DK">Germany</option>
				<option value="FR">France</option>
			</select>
		</div>
		<div class="form__item">
			<label for="input--to">To</label>
			<select multiple class="select" name="select-to" id="input--to">
				<option value="bram">Bram Hoosemans <bram@luyfel.nl></option>
				<option value="sven">Sven Hanssen <sven@luyfel.nl></option>
				<option value="michael">Michael Krens <m.krens@gmail.com></option>
			</select>
		</div>
		<div class="form__item">
			<label for="input--message">Message</label>
			<textarea id="input--message" placeholder="Write a short but powerful message"></textarea>
		</div>
	</fieldset>
</form>

Styleguide base.forms
*/

form {

	@include media-query(lap-and-up) {
		// margin-top: $b/2;
	}

}

.input--text,
input[sr-selectize] {
  color: setting(colors, font);
  background-color: #fff;
	display: block;
	border-radius: map-get($forms, border-radius);
	border: 1px solid map-get($forms, border-color);
	padding: 9px $b/2;
	// margin: 4px 0;
	transition: border .4s, box-shadow .4s;
	width: 100%;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.05), 0 -1px 1px #fff, 0 1px 0 #fff;
	-webkit-appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &:hover:not(:disabled) {
		border-color: #999;
	}

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

	&:focus:not(:disabled) {
		outline: 0;
		border-color: map-get($forms, border-color-focus);
		box-shadow: 0px 0px 3px 0px rgba(map-get($forms, border-color-focus), 0.20);
	}

	@include placeholder {
		color: map-get($forms, placeholder-color);
		font-weight: setting(font-weights, normal);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&.placeholder {
		color: map-get($forms, placeholder-color);
	}

	.has-error & {
		border-color: setting(colors, error);
	}

	label + & {
    // margin for form input elements
    &, & + .selectize-control {
      margin-top: $b/2;
    }
	}

  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: .4;
    pointer-events: none;
  }

  &.input--center {
    text-align: center;
  }

  &.has-error, &.ng-invalid.ng-dirty, &.ng-invalid.ng-touched {
    border-color: setting(colors, error);
  }

	&.is-ok, &.ng-valid.ng-dirty {
		border-color: setting(colors, ok);
	}

}

// https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
input[type="number"].hide-number-controls::-webkit-outer-spin-button,
input[type="number"].hide-number-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].hide-number-controls {
  -moz-appearance: textfield;
}

.selectize-input.required.not-full {
  border-color: setting(colors, error);
}

.form--error {
    color: setting(colors, error);
}

.form--warning {
    color: setting(colors, warning);
}

.qr-code {
    border-radius: map-get($forms, border-radius);
    border: 1px solid map-get($forms, border-color);
    width: 200px;
    height: 200px;
    margin: auto;
    text-align: center;

    loading {
      position: relative;
      top: 89px;
    }
}

.form--finished {
  .icon {
    color: setting(colors, ok);
  }
}


//input[sr-selectize], select[sr-selectize] {
//  & + .selectize-control {
//    margin-top: $b/2;
//  }
//}

label + div.intl-tel-input {
  margin-top: $b/2;
  display: block;
}

textarea.input--text {
	resize: vertical;
	width: 100%;

	&.autogrow {
		transition: height .4s;
	}

}

label {
	display: inline-block;
	max-width: 100%;
	font-weight: setting(font-weights, bold);
	color: setting(colors, font-dark);
	text-align: left;
  .rtl & {
    text-align: right;
  }
}

.label-low {
  	display: inline;
		margin-bottom: 2px;
		cursor: pointer;
		font-weight: setting(font-weights, normal);
		color: setting(colors, font);
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;

	+ fieldset {
		margin-top: $b;
	}
}

legend {
	font-weight: setting(font-weights, bold);
	color: setting(colors, font-dark);
}

select {
	width: 100%;
	opacity: 0;
	height: 51px;
}

.form__item, .sr-layout.form__item {
	margin-top: $b/2;
}

.checkbox {
	padding-left: map-get($forms, checkbox-width)*1.5;
	vertical-align: middle;
	cursor: pointer;
	text-align: left;

  .rtl & {
    text-align: right;
    padding-right: map-get($forms, checkbox-width)*1.5;
    padding-left: initial;
  }

	label {
		display: inline;
		margin-bottom: 0;
		cursor: pointer;
		font-weight: setting(font-weights, normal);
		color: setting(colors, font);
	}

	input {
		float: left;
		margin-left: map-get($forms, checkbox-width)*-1.5;

    .rtl & {
      float: right;
      margin-right: map-get($forms, checkbox-width)*-1.5;
      margin-left: initial;
    }

    span {
      pointer-events: none;  // needed for fastclick to work
    }

		&:checked,
		&:not(:checked) {
			@extend .context;

			+ span {
				display: inline-block;
				width: map-get($forms, checkbox-width);
				height: map-get($forms, checkbox-height);
				content: "";
				vertical-align: middle;
				margin-right: map-get($forms, checkbox-width)*0.5;
				margin-left: map-get($forms, checkbox-width)*-1.5;
				margin-top: -2px;
				color: #fff;

        .rtl & {
          margin-left: map-get($forms, checkbox-width)*0.5;
          margin-right: map-get($forms, checkbox-width)*-1.5;
        }

				&:before {
					@extend .icon:before;
					@extend .icon--tick:before;

					font-size: 16px;
					line-height: 16px;
					width: 16px;
					height: 16px;
					vertical-align: top !important;
				}
			}
		}
	}

	input + span {
		background: #fff;
		border: 1px solid map-get($forms, border-color);
		border-radius: map-get($forms, border-radius);
		position: relative;
	}

	&:hover input + span {
		border-color: map-get($forms, border-color-hover);
	}

	input:checked + span {
		color: darken(setting(colors, secundary), 10);
		border-color: setting(colors, secundary);
	}

	input:not(:checked):disabled + span {
		opacity: .5;
	}

	input:checked:disabled + span {
		opacity: .5;
	}

}

.radio {
	padding-left: map-get($forms, radio-width)*1.5;
	vertical-align: middle;
	cursor: pointer;
	text-align: left;

  .rtl & {
    text-align: right;
  }

	label {
		display: inline;
		margin-bottom: 0;
		cursor: pointer;
		font-weight: setting(font-weights, normal);
		color: setting(colors, font);
	}

	input {
		float: left;
		margin-left: map-get($forms, radio-width)*-1.5;

  .rtl & {
    float: right;
    margin-left: inherit;
    margin-right: map-get($forms, radio-width)*-1.5;
  }

		&:checked,
		&:not(:checked) {
			@extend .context;

			+ span {
				display: inline-block;
				width: map-get($forms, radio-width);
				height: map-get($forms, radio-height);
				content: "";
				vertical-align: middle;
				margin-right: map-get($forms, radio-width)*0.5;
				margin-left: map-get($forms, radio-width)*-1.5;
				margin-top: -2px;
        .rtl & {
          margin-right: map-get($forms, radio-width)*-1.5;
          margin-left: map-get($forms, radio-width)*0.5;
        }
			}
		}
	}

	input + span {
		background: #fff;
		border: 1px solid map-get($forms, border-color);
		border-radius: 50%;
		position: relative;
	}

	&:hover input + span {
		border-color: map-get($forms, border-color-hover);
	}

	input:checked + span {
		border-color: setting(colors, secundary);

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 8px;
			height: 8px;
			left: 4px;
			top: 4px;
			background: setting(colors, secundary);
			border-radius: 50%;
		}
	}

	input:not(:checked):disabled + span {
		opacity: .5;
	}

	input:checked:disabled + span {
		opacity: .5;
	}

	&--image {
		position: relative;
		padding-left: 0;
		margin-top: $b/2;

		input[type=radio] + span {
			position: absolute !important;
			top: $b/2;
			left: $b/2;
			margin: 0 !important;
		}

		label {
			display: block;
			border: 1px solid setting(colors, border);
			border-radius: setting(border-radius);

			&:hover {
				border-color: setting(colors, border-dark);
			}
		}

		img {
			border-radius: setting(border-radius);
			width: 100%;
			max-width: none;
		}

		@include media-query(lap-and-up) {
			margin-top: $b;
		}
	}

}


/*
Forms - Inline

Nothing fancy, just add the sr-layout class to a form item and use the sr-layout item classes.

Markup:
<form class="form form--inline">
	<fieldset>
		<div class="form__item sr-layout">
			<label for="input--name" class="layout__item one-quarter">Name</label><!--
			--><div class="layout__item three-quarters">
				<input type="text" class="input--text" id="input--name" placeholder="Enter your name">
			</div>
		</div>
	</fieldset>
</form>

Styleguide base.forms.inline
*/

.form--inline {
	label {
		text-align: right;
		padding: $b/2 0;

    .rtl & {
      text-align: right;
    }

	}
  .inline--data {
    padding-top: $b/2;
    padding-bottom: $b/2;

  }
}
