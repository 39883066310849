@function map-get-deep($map, $keys...) {
	$value: $map;
	@each $key in $keys {
		$value: map-get($value, $key);
	}
	@return $value;
}


@function diapositive($color) {
	@if (lightness($color) > 50) {
		@return #000;
	} @else {
		@return #fff;
	}
}

@function setting($keys...) {
	$value: $settings;
	@each $key in $keys {
		$value: map-get($value, $key);
	}
	@return $value;
}

@function z-index($key) {
	@return index(map-get($settings, z-index), $key);
}
