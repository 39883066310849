html {
	font-size: setting(rem);
	font-family: setting(font-family), 'Open Sans', sans-serif;
	font-weight: setting(font-weights, light);
	line-height: setting(line-height) / setting(font-size);
	overflow-y: scroll;
	overflow-x: auto;
	min-height: 100%;
	height: 100%;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	hyphens: none;

}

body {
	min-height: 100%;
	min-height: 100vh;
}

html,
input,
button {
	color: setting(colors, font);
}

html {
	&.no-scroll {
		overflow: hidden;
	}
}

// .has-overlay {
// 	overflow: hidden;

// 	> body {
// 		height: 100%;
// 		overflow-y: scroll;
// 	}

// 	@media screen and (max-width: $lap-start) {
// 		> body {
// 			overflow: hidden;
// 			height: 100%;
// 		}
// 	}
// }
