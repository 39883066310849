/*
Calendar

Markup:
<div class="calendar">
	<div class="calendar__controls">
		<a href="#" class="calendar__control"><i class="icon icon--arrow-left"></i></a><!--
		--><div class="calendar__control calendar__month">July 2014</div><!--
		--><a href="#" class="calendar__control"><i class="icon icon--arrow-right"></i></a>
	</div>
	<div class="calendar__days-in-week">
		<div class="calendar__day-in-week">Mo</div>
		<div class="calendar__day-in-week">Tu</div>
		<div class="calendar__day-in-week">We</div>
		<div class="calendar__day-in-week">Th</div>
		<div class="calendar__day-in-week">Fr</div>
		<div class="calendar__day-in-week">Sa</div>
		<div class="calendar__day-in-week">Su</div>
	</div>
	<div class="calendar__days">
		<div class="calendar__day calendar__day--other"><a href="#">30</a></div>
		<div class="calendar__day"><a href="#">1</a></div>
		<div class="calendar__day"><a href="#">2</a></div>
		<div class="calendar__day"><a href="#">3</a></div>
		<div class="calendar__day"><a href="#">4</a></div>
		<div class="calendar__day"><a href="#">5</a></div>
		<div class="calendar__day"><a href="#">6</a></div>
		<div class="calendar__day"><a href="#">7</a></div>
		<div class="calendar__day"><a href="#">8</a></div>
		<div class="calendar__day active"><a href="#">9</a></div>
		<div class="calendar__day"><a href="#">10</a></div>
		<div class="calendar__day"><a href="#">11</a></div>
		<div class="calendar__day"><a href="#">12</a></div>
		<div class="calendar__day"><a href="#">13</a></div>
		<div class="calendar__day"><a href="#">14</a></div>
		<div class="calendar__day calendar__day--today"><a href="#">15</a></div>
		<div class="calendar__day"><a href="#">16</a></div>
		<div class="calendar__day"><a href="#">17</a></div>
		<div class="calendar__day"><a href="#">18</a></div>
		<div class="calendar__day"><a href="#">19</a></div>
		<div class="calendar__day"><a href="#">20</a></div>
		<div class="calendar__day"><a href="#">21</a></div>
		<div class="calendar__day"><a href="#">22</a></div>
		<div class="calendar__day"><a href="#">23</a></div>
		<div class="calendar__day"><a href="#">24</a></div>
		<div class="calendar__day"><a href="#">25</a></div>
		<div class="calendar__day"><a href="#">26</a></div>
		<div class="calendar__day"><a href="#">27</a></div>
		<div class="calendar__day"><a href="#">28</a></div>
		<div class="calendar__day"><a href="#">29</a></div>
		<div class="calendar__day"><a href="#">30</a></div>
		<div class="calendar__day"><a href="#">31</a></div>
		<div class="calendar__day calemdar__day--other"><a href="#">1</a></div>
		<div class="calendar__day calendar__day--other"><a href="#">2</a></div>
		<div class="calendar__day calendar__day--other"><a href="#">3</a></div>
	</div>
</div>

Styleguide components.calendar
*/

.calendar {
	margin: $b auto 0 auto;
	text-align: center;
	max-width: 600px;

	&__controls {
		@extend .clearfix;
	}

	&__control {
		float: left;
		display: block;
		width: percentage(1/7);
		padding: 0 0 $b 0;

    .rtl & {
      float: right;
    }

	}

	&__month {
		width: percentage(5/7);
	}

	&__days-in-week {
		@extend .clearfix;
	}

	&__day-in-week {
		float: left;
		display: block;
		width: percentage(1/7);
		padding: 0 $b/4 $b $b/4;
    .rtl & {
      float: right;
    }
	}

	&__days {
		@extend .clearfix;

		border-radius: setting(border-radius);
		overflow: hidden;
		border: {
			style: solid;
			width: 0 1px 1px 0;
			color: setting(colors, border);
		};
	}

	&__day {
		float: left;
		display: block;
		width: percentage(1/7);
		border: {
			style: solid;
			width: 1px 0 0 1px;
			color: setting(colors, border);
		};

    .rtl & {
      float: right;
    }

		a {
			display: block;
			height: 0;
			padding-top: 50%;
			line-height: 0;
			padding-bottom: 50%;

			&:hover {
				text-decoration: none;
				background: setting(colors, background-light);
			}

			&:target {
				background: red;
			}
		}

		&:first-child {
			border-top-left-radius: setting(border-radius);
		}

		&--other a {
			color: setting(colors, font-light);
		}

		&.active a {
			background: setting(colors, primary);
			color: #fff;

			&:hover {
				background: darken(setting(colors, primary), 10);
			}
		}

		&--today a {
			background: setting(colors, background-light);
			font-weight: setting(font-weights, bold);
		}
	}
}
