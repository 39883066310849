/*
This or this'

Simple options object to provide multiple choices

Markup:
<ul class="this-or-this {{modifier_class}}">
	<li class="this-or-this__this"><a href="javascript:history.back()">Back to the previous page</a></li>
	<li class="this-or-this__or"><span>or</span></li>
	<li class="this-or-this__this"><a href="index.html">go to the homepage</a></li>
</ul>

.this-or-this--inline - Inline

Styleguide objects.this-or-this
*/

.this-or-this{
	width: 100%;
	list-style-type: none;
	position: relative;

	&__or {
		margin-top: $b;
		margin-bottom: $b;
		position: relative;
		text-align: center;
		text-transform: uppercase;

		span {
			display: inline-block;
			background: #fff;
			padding: 0 $b/4;
			position: relative;
			z-index: 2;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			height: 0;
			width: 100%;
			border-color: setting(colors, border);
			border-style: solid;
			border-width: 1px 0 0 0;
			z-index: 1;
			opacity: .5;
		}

    &.main--module {

      margin-top: $b/2;
      margin-bottom: $b/2;

      &:before {
        background: setting(colors, border);

      }

      span {
        background: setting(colors, background-light);
      }

    }

	}

	&--inline {
		display: table;
		text-align: center;
		table-layout: fixed;

		.this-or-this {
			&__this,
			&__or {
				display: table-cell;
				vertical-align: middle;
			}

			&__or {
				span {
					display: none;
				}

				&:before {
					display: none;
				}
			}
		}
	}
}

.modal {
	.this-or-this__or span {
		background: setting(colors, primary);
	}

	&--invert {
		.this-or-this__or span {
			background: #fff;
		}
	}
}
