/*
Input groups

Markup:
<div class="input-group">
	<label for="input-price" class="input-group__addon">&euro;</label>
	<input type="text" id="input-price" class="input--text" placeholder="300">
	<label for="input-price" class="input-group__addon">.00</label>
</div>

Styleguide components.input-group
*/

.input-group {
	@extend .input--text;

	position: relative;
	display: table;
	border-collapse: seperate;
	width: 100%;
	overflow: hidden;
  padding: 0;

  .input--text, &__addon {
    padding-left: $b/2!important;

  }

	.input--text {
    &, &:focus {
      margin: 0;
      border: 0;
      padding: 0;
      box-shadow: none;
    }
	}

	&__addon {
		display: table-cell;
		width: 1%;
		white-space: nowrap;
    padding-top: 7px!important;
    padding-bottom: 7px!important;

    &:first-child, &:last-child {
      //padding-left: $b/2!important;
      //padding-right: $b/2!important;
    }


    &--button {
      vertical-align: top;
      padding: 0 0 0 $b/2!important;

      .button {
        margin: 0;
      }
    }

		&:last-child {
			padding-left: 0;
			padding-right: $b/2;

			.button {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

	}

}
