
@include keyframes(bounce-to-bottom) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-15px));
  }
  60% {
    @include transform(translateY(-5px));
  }
}

@include keyframes(bounce-to-top) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(15px));
  }
  60% {
    @include transform(translateY(5px));
  }
}


@include keyframes(bounce-to-right) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateX(0));
  }
  40% {
    @include transform(translateX(-15px));
  }
  60% {
    @include transform(translateX(-5px));
  }
}

@include keyframes(bounce-to-left) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateX(0));
  }
  40% {
    @include transform(translateX(15px));
  }
  60% {
    @include transform(translateX(5px));
  }
}

.sr-arrow {
  display: none;
  z-index: z-index(arrow);
  pointer-events: none;
  @include transition(all 0.3s ease-in-out);

  &--on-top {
    z-index: z-index(arrow-on-top);
  }

  svg {
    width: 40px;
    height: 40px;
    pointer-events: none;
    @include transition(all 0.3s linear);

    path {
      fill: setting(colors, primary);
    }

  }

  &.sr-arrow-top {
    @include animation(bounce-to-top 2s infinite);
    svg {
      @include transform(rotate(180deg));
    }
  }

  &.sr-arrow-bottom {
    @include animation(bounce-to-bottom 2s infinite);
  }

  &.sr-arrow-left {
    @include animation(bounce-to-left 2s infinite);
    svg {
      @include transform(rotate(90deg));
    }
  }

  &.sr-arrow-right {
    @include animation(bounce-to-right 2s infinite);
    svg {
      @include transform(rotate(-90deg));
    }
  }

}
