/*
Float

Float elements.

Markup:
<div class="float {{modifier_class}}">I'm floated</div>

.float--left - Float left
.float--center - Float center
.float--right - Float right

Styleguide trumps.float
*/

.float--left, .pull-left {
	float: left !important;
  .rtl & {
    float: right !important;
  }
}

.float--right, .pull-right {
	float: right !important;
  .rtl & {
    float: left !important;
  }
}

.float--center {
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.clear {
  clear: both;
}

.clear--left, .clear-left {
  clear: left;
  .rtl & {
    clear: right;
  }
}

.clear--right, .clear-right {
  clear: right;
  .rtl & {
    clear: left;
  }
}
