// largely taken from signer-pages
.block {
  border: 1px solid setting(colors, light);
  border-radius: setting(border-radius);

  &__header {
    display: inline-block;
    border-radius: setting(border-radius);
    width: 100%;
    padding: 0 $b $b $b;
    background: setting(colors, lighter);
    text-align: center;
  }

  &__divider {
    position: relative;
    padding: 0 $b 0 $b;

    .button {
      margin-top: 0 !important;
    }

    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 50%;
      left: 0;
      background: setting(colors, lighter);
      z-index: -1;
    }
  }

  &__item {
    padding: 0 $b $b $b;
    background: setting(colors, lighter);
  }

  &__clickable {
    @include transition (border-color 200ms ease-in-out);
    cursor: pointer;
  }

  &__clickable:hover {
    border-color: setting(colors, primary);
  }

}
