<template>
  <img
    :src="require('@/images/sign-handshake.svg')"
    class="sign-handshake"
    :alt="$gettext('Signer handshake')"
  />
</template>
<style scoped>
.sign-handshake {
  width: 102px;
  height: 123px;
  object-fit: contain;
}
</style>
