/*
Buttons

Quickly create a style buttons. As a best practice, we highly recommend using the button element whenever possible to ensure matching cross-browser rendering.

Markup:
<button class="button {{modifier_class}}">Button (button.button)</button>
<a href="#" class="button {{modifier_class}}">Button (a.button)</a>

.disabled - 50% opacity
.button--near - Less top margin
.button--invert - Inverted
.button--line - Bordered button
.button--line.button--line--grey - Bordered grey button
.button--line.button--invert - Bordered button, inverted
.button--link - Button dressed as a link
.button--link.button--invert - Link button, inverted
.button--link.button--link--grey - Button dressed as a link, in grey
.button--darken - Dark opaque button
.button--success - Success
.button--warning - Warning
.button--error - error
.button--linkedin - LinkedIn
.button--twitter - Twitter
.button--facebook - Facebook
.button--google - Google
.button--dropbox - Dropbox
.button--onedrive - Drive
.button--salesforce

Styleguide objects.button
*/

@mixin button-paint($color, $important: false) {
	@if $important != false {

		background-color: $color !important;
		border-color: $color !important;

		&:hover {
			//background-color: lighten($color, 10) !important;
			//border-color: lighten($color, 10) !important;
      opacity: setting(hover-opacity);
		}

	} @else {
		background-color: $color;
		border-color: $color;

		&:hover {
			//background-color: lighten($color, 10);
			//border-color: lighten($color, 10);
      opacity: setting(hover-opacity);
		}
	}
  &.button--line {
    background-color: #fff !important;
    color: $color !important;
    border-color: $color !important;

    &:hover:not(:disabled) {
      background-color: $color !important;
    }
  }
}

.button, a[type=button].button {
	display: inline-block;
	vertical-align: middle;
	font: inherit;
	text-align: center;
	margin: 0;
	cursor: pointer;
	overflow: visible;
	// margin: (5px)/$font-size*1em 0 5px/$font-size*1em;
	border-radius: setting(border-radius);
	background-color: setting(colors, primary);
	border: 1px solid setting(colors, primary);
	padding: $b/4 $b/2;
	color: #fff !important;
	outline: none !important;
	transition: border .2s, color .2s, background .2s, opacity .2s;
	font-weight: setting(font-weights, bold);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  -webkit-appearance: inherit;

	&,
	&:hover {
		text-decoration: none!important;
	}

	&:hover {
    opacity: setting(hover-opacity);
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	p & {
		margin-top: 5px;
	}

	.icon {
		margin-left: $b/2;

		&:first-child {
			margin-left: 0;
			margin-right: $b/2;
		}

		.rtl &:first-child {
			margin-left: $b/2;
			margin-right: 0;
		}

	}

	&--near {
		margin-top: $b/2;
	}

  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: .4;
    pointer-events: none;
  }

}

.button--invert {
	background-color: #fff;
	border-color: #fff;
	color: setting(colors, primary) !important;
}

.button--line {
	background-color: #fff;
	border-color: setting(colors, primary);
	color: setting(colors, primary) !important;

	&:hover:not(:disabled) {
		background-color: setting(colors, primary);
		border-color: setting(colors, primary);
		color: #fff !important;
	}

	&.button--invert {
		background-color: transparent;
		border-color: #fff;
		color: #fff !important;

		&:hover {
			background-color: rgba(255,255,255,0.25);
		}
	}

	&--grey {
		border-color: setting(colors, border);
		color: setting(colors, font-light) !important;
	}
}

.button--link {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: setting(font-weights, normal);

  &,
  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: setting(colors, primary) !important;
  }

  &:hover {
    text-decoration: underline!important;
  }

  &.button--invert {
    &,
    &:hover {
      color: #fff !important;
    }
  }

  &--grey {
    color: setting(colors, font-light) !important;

    &:hover{
      color: setting(colors, primary) !important;
    }
  }
}

.button--darken {
	background-color: rgba(0,0,0,0.25);
	border-color: transparent;

	&:hover {
		background-color: rgba(0,0,0,0.15);
		border-color: transparent;
	}
}

.button--success {
	@include button-paint(setting(colors, secundary));
}

.button--warning {
	@include button-paint(setting(colors, warning), true);
}

.button--error {
	@include button-paint(setting(colors, error), true);
}

.button--grey {
	@include button-paint(setting(colors, background-light), true);

	color: setting(colors, font-dark) !important;
}

.button--linkedin {
	@include button-paint(setting(brand-colors, linkedin), true);
}

.button--twitter {
	@include button-paint(setting(brand-colors, twitter), true);
}

.button--facebook {
	@include button-paint(setting(brand-colors, facebook), true);
}

.button--social {
  padding: 0px;
  .icon {
    margin: 0;
  }
}

.button--social--text {
  padding: 8px;

  .button--social--with-icon & {
    margin-left: 40px;
  }

}

.social-icon-bg {
  width: 40px;
  height: 40px;
  float: left;
  padding: 11px;
  background: #fff;
  border-radius: 2px;
  .rtl & {
    float: right;
  }

  .social-icon {
    max-height: 18px;
    vertical-align: inherit;
  }

}

.button--google {
	@include button-paint(setting(brand-colors, google), true);
  font-family: 'Roboto', 'Open Sans', sans-serif;
  font-weight: 500;
}

.button--salesforce {
	@include button-paint(setting(brand-colors, salesforce), true);
}


.button--dropbox {
	@include button-paint(setting(brand-colors, dropbox), true);
}

.button--onedrive {
	@include button-paint(setting(brand-colors, onedrive), true);
}
//
//button, .button, input[type=button] {
//  &:disabled, &.disabled, &[disabled] {
//    cursor: not-allowed;
//    opacity: 0.5;
//
//    &:hover, &:active, &:focus {
//      background-color: inherit;
//      color: inherit!important;
//    }
//
//  }
//}

/*
Buttons - Sizes

Change the size of the buttons

Markup:
<button class="button {{modifier_class}}">Button (button.button)</button>
<a href="#" class="button {{modifier_class}}">Button (a.button)</a>

.button--small - Small
.button--large - Large
.button--fluid - Fluid

Styleguide objects.button.sizes
*/

.button--small {
	padding: $b/8 $b/2;
	margin: $b/2 0;

	@include font-size(13);
}

.button--large {
	padding: 12px 24px;

	@include font-size(16, 1);
}

.button--fluid {
  width: 100%;
}

.button--upload {
  position: relative;
  overflow: hidden;
  margin: 10px;

  input[type=file]{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.button--top {
  margin-top: 0;
}
