/*
Links

Markup:
<a href="#" class="link {{modifier_class}}">I'm a link</a>

.link--grey - Grey link

Styleguide base.links
*/

a {
	color: setting(colors, primary);
	text-decoration: none;
	transition: border .2s, color .2s, background .2s, opacity .2s;

	&:hover {
		text-decoration: underline;
	}

	&.link--grey {
		color: setting(colors, font-light);

		&:hover {
			color: setting(colors, primary);
		}
	}

	&.link--disabled {
		&, &:hover {
      color: setting(colors, font-light);
      text-decoration: none;
      pointer-events: none;
      cursor: default;
		}
	}

  &.break--all {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  }

}
