/*
Images

Images have a max-width of 100% by default. If you want to support retina and/or responsive images, use picturefill, the responsive image polyfill for picture, srcset and sizes. For more info, visit <a href="http://scottjehl.github.io/picturefill/">http://scottjehl.github.io/picturefill</a>.

Markup:
<img src="http://placehold.it/400x300" alt="Image" />
<img src="http://placehold.it/400x300" srcset="http://placehold.it/400x300, http://placehold.it/800x600 2x" alt="Image with retina support">
<picture>
	<source srcset="http://placehold.it/732x360, http://placehold.it/1464x720 2x" media="(min-width: 800px)">
	<source srcset="http://placehold.it/296x240, http://placehold.it/592x480 2x">
	<img src="http://placehold.it/296x240" alt="Image with retina and breakpoints support">
</picture>

Styleguide base.images
*/

img {
	max-width: 100%;
	height: auto;
	font-style: italic;
	vertical-align: bottom;
}

figure {
	margin: 0;
}
