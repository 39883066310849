/*
Sortable

No styleguide reference
*/

.sortable {
	list-style: none;

	> * {
		margin-top: $b/2;
		position: relative;
		padding-left: $b*1.25;

		&:before {
			@extend .icon:before;
			@extend .icon--sort:before;

			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -8px;
			color: setting(colors, font-light);
		}
	}

	&-placeholder {
		border: 2px dashed setting(colors, border);
		border-radius: setting(border-radius);
		margin-top: $b/2;
	}
}
