/*
Split

Split content side by side

Markup:
<div class="split">
	<div class="split__item">Item 1</div>
	<div class="split__item">Item 2</div>
</div>

Styleguide objects.split
*/

.split {
	@extend .clearfix;

	text-align: right;

	&__item {
		text-align: left;
		float: left;
		clear: left;
    .rtl & {
      float: right;
      clear: right;
    }

		+ .split__item {
			text-align: right;
			float: right;
			clear: none;
      .rtl & {
        float: left;
        text-align: left;
      }
		}
	}
}
