/*
Flags

Place any image and text-like content side-by-side. The difference between this one and .media is that the body is aligned vertically in the middle and if we want to place the object at the other side we need to switch the positions of the object and body.

Markup:
<div class="flag">
	<div class="flag__object">
		<img src="http://placehold.it/96x96">
	</div>
	<div class="flag__body">
		<p>Lorem ipsum dolor sit amet</p>
	</div>
</div>
<div class="flag">
	<div class="flag__body">
		<p>Lorem ipsum dolor sit amet</p>
	</div>
	<div class="flag__object">
		<img src="http://placehold.it/96x96">
	</div>
</div>

Styleguide objects.flag
*/

.flag {
	display: table;
	width: 100%;
	margin-top: $b;

	&__object,
	&__img,
	&__body {
		display: table-cell;
		vertical-align: middle;
	}

	&__object,
	&__img {
		padding-right: $b;

    .rtl & {
      padding-right: inherit;
      padding-left: $b;
    }

		img {
			display: block;
			max-width: none;
		}
	}

	&__body {
		width: 100%;

		&,
		> :first-child {
			margin-top: 0;
		}
	}

	&__body + .flag__object {
		padding-right: 0;
		padding-left: $b;

    .rtl & {
      padding-right: $b;
      padding-left: 0;
    }

	}

}
