.v-btn {
  text-transform: none;
  &:hover {
    text-decoration: none;
  }
}

.v-list__tile:hover {
  text-decoration: none;
}

// TODO: Figure out why this doesn't work when setting $button-font-weight = 600
.v-btn {
  font-weight: 600;
}
