/*
Icons

Webfont icons generated by grunt-webfont from a bunch of svg files.

Markup:
<i class="icon {{modifier_class}}"></i>

.icon--arrow-down - arrow-down
.icon--arrow-left - arrow-left
.icon--arrow-right--25 - arrow-right--25
.icon--arrow-right - arrow-right
.icon--cabinet - cabinet
.icon--calendar--25 - calendar--25
.icon--calendar - calendar
.icon--check--circle - check--circle
.icon--check - check
.icon--checkbox--25 - checkbox--25
.icon--checkbox--empty--25 - checkbox--empty--25
.icon--checkbox--empty - checkbox--empty
.icon--checkbox - checkbox
.icon--circle--add - circle--add
.icon--circle--checkmark - circle--checkmark
.icon--circle--clock - circle--clock
.icon--circle--cross - circle--cross
.icon--circle--danger - circle--danger
.icon--circle--delete - circle--delete
.icon--circle--exclamation-mark - circle--exclamation-mark
.icon--circle--question--25 - circle--question--25
.icon--circle--question - circle--question
.icon--circle--tick - circle--tick
.icon--companies--25 - companies--25
.icon--companies - companies
.icon--creditcard--25 - creditcard--25
.icon--creditcard - creditcard
.icon--cross - cross
.icon--document--25 - document--25
.icon--document-add - document-add
.icon--document-stack--25 - document-stack--25
.icon--document-stack - document-stack
.icon--document - document
.icon--documents--25 - documents--25
.icon--documents - documents
.icon--dropbox - dropbox
.icon--eye - eye
.icon--facebook - facebook
.icon--gear--25 - gear--25
.icon--gear - gear
.icon--globe - globe
.icon--google-drive - google-drive
.icon--google-plus - google-plus
.icon--happy - happy
.icon--linkedin - linkedin
.icon--lock-square - lock-square
.icon--lock - lock
.icon--logout - logout
.icon--love - love
.icon--microsoft-onedrive - microsoft-onedrive
.icon--onedrive - onedrive
.icon--paper-airplane - paper-airplane
.icon--percentage - percentage
.icon--play - play
.icon--sandbox - sandbox
.icon--signature--25 - signature--25
.icon--signature - signature
.icon--sort - sort
.icon--text--25 - text--25
.icon--text-down - text-down
.icon--text-up - text-up
.icon--text - text
.icon--tick - tick
.icon--trash--25 - trash--25
.icon--trash - trash
.icon--twitter - twitter
.icon--user--25 - user--25
.icon--user--add - user--add
.icon--user - user
.icon--users - users

Styleguide objects.icon
*/

.icon:before {
	font-size: 16px;
	line-height: 16px;
	width: 16px;
	height: 16px;
	display: inline-block;
	text-align: center;
}

.icon--no-font-size:before {
  font-size: inherit;
  line-height: inherit;
  width: auto;
  height: auto;
}

.icon--25:before {
	font-size: 25px;
	line-height: 25px;
	width: 25px;
	height: 25px;
}

.icon--32:before {
	font-size: 32px;
	line-height: 32px;
	width: 32px;
	height: 32px;
}

.icon--50:before {
	font-size: 50px;
	line-height: 50px;
	width: 50px;
	height: 50px;
}

.icon--64:before {
	font-size: 64px;
	line-height: 64px;
	width: 64px;
	height: 64px;
}


@font-face {
	font-family:"icon";
	src:url("../fonts/icon/icon.7a453d59af5d8cd36aeadf80bc04eac4.eot");
	src:url("../fonts/icon/icon.7a453d59af5d8cd36aeadf80bc04eac4.eot?#iefix") format("embedded-opentype"),
		url("../fonts/icon/icon.7a453d59af5d8cd36aeadf80bc04eac4.woff") format("woff"),
		url("../fonts/icon/icon.7a453d59af5d8cd36aeadf80bc04eac4.ttf") format("truetype"),
		url("../fonts/icon/icon.7a453d59af5d8cd36aeadf80bc04eac4.svg#icon") format("svg");
	font-weight:normal;
	font-style:normal;
}



	.icon:before {
		font-family: "icon";
		display: inline-block;
		vertical-align: middle; // or inherit
		text-align: center;
		font-weight: normal;
		font-style: normal;
		speak: none;
		text-decoration: inherit;
		text-transform: none;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}



	// Icons
	
		
			
			.icon--arrow-down:before {
				content:"\f101";
				
				
				
				
			}
		
	
		
			
			.icon--arrow-left:before {
				content:"\f102";
				
				
				
				
			}
		
	
		
			
			.icon--arrow-right--25:before {
				content:"\f103";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--arrow-right:before {
				content:"\f104";
				
				
				
				
			}
		
	
		
			
			.icon--cabinet:before {
				content:"\f105";
				
				
				
				
			}
		
	
		
			
			.icon--calendar--25:before {
				content:"\f106";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--calendar:before {
				content:"\f107";
				
				
				
				
			}
		
	
		
			
			.icon--check--circle:before {
				content:"\f108";
				
				
				
				
			}
		
	
		
			
			.icon--check:before {
				content:"\f109";
				
				
				
				
			}
		
	
		
			
			.icon--checkbox--25:before {
				content:"\f10a";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--checkbox--empty--25:before {
				content:"\f10b";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--checkbox--empty:before {
				content:"\f10c";
				
				
				
				
			}
		
	
		
			
			.icon--checkbox:before {
				content:"\f10d";
				
				
				
				
			}
		
	
		
			
			.icon--circle--add:before {
				content:"\f10e";
				
				
				
				
			}
		
	
		
			
			.icon--circle--checkmark:before {
				content:"\f10f";
				
				
				
				
			}
		
	
		
			
			.icon--circle--clock:before {
				content:"\f110";
				
				
				
				
			}
		
	
		
			
			.icon--circle--cross:before {
				content:"\f111";
				
				
				
				
			}
		
	
		
			
			.icon--circle--danger:before {
				content:"\f112";
				
				
				
				
			}
		
	
		
			
			.icon--circle--delete:before {
				content:"\f113";
				
				
				
				
			}
		
	
		
			
			.icon--circle--exclamation-mark:before {
				content:"\f114";
				
				
				
				
			}
		
	
		
			
			.icon--circle--question--25:before {
				content:"\f115";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--circle--question:before {
				content:"\f116";
				
				
				
				
			}
		
	
		
			
			.icon--circle--tick:before {
				content:"\f117";
				
				
				
				
			}
		
	
		
			
			.icon--companies--25:before {
				content:"\f118";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--companies:before {
				content:"\f119";
				
				
				
				
			}
		
	
		
			
			.icon--creditcard--25:before {
				content:"\f11a";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--creditcard:before {
				content:"\f11b";
				
				
				
				
			}
		
	
		
			
			.icon--cross:before {
				content:"\f11c";
				
				
				
				
			}
		
	
		
			
			.icon--document--25:before {
				content:"\f11d";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--document-add:before {
				content:"\f11e";
				
				
				
				
			}
		
	
		
			
			.icon--document-stack--25:before {
				content:"\f11f";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--document-stack:before {
				content:"\f120";
				
				
				
				
			}
		
	
		
			
			.icon--document:before {
				content:"\f121";
				
				
				
				
			}
		
	
		
			
			.icon--documents--25:before {
				content:"\f122";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--documents:before {
				content:"\f123";
				
				
				
				
			}
		
	
		
			
			.icon--dropbox:before {
				content:"\f124";
				
				
				
				
			}
		
	
		
			
			.icon--eye:before {
				content:"\f125";
				
				
				
				
			}
		
	
		
			
			.icon--facebook:before {
				content:"\f126";
				
				
				
				
			}
		
	
		
			
			.icon--gear--25:before {
				content:"\f127";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--gear:before {
				content:"\f128";
				
				
				
				
			}
		
	
		
			
			.icon--globe:before {
				content:"\f129";
				
				
				
				
			}
		
	
		
			
			.icon--google-drive:before {
				content:"\f12a";
				
				
				
				
			}
		
	
		
			
			.icon--google-plus:before {
				content:"\f12b";
				
				
				
				
			}
		
	
		
			
			.icon--happy:before {
				content:"\f12c";
				
				
				
				
			}
		
	
		
			
			.icon--linkedin:before {
				content:"\f12d";
				
				
				
				
			}
		
	
		
			
			.icon--lock-square:before {
				content:"\f12e";
				
				
				
				
			}
		
	
		
			
			.icon--lock:before {
				content:"\f12f";
				
				
				
				
			}
		
	
		
			
			.icon--logout:before {
				content:"\f130";
				
				
				
				
			}
		
	
		
			
			.icon--love:before {
				content:"\f131";
				
				
				
				
			}
		
	
		
			
			.icon--microsoft-onedrive:before {
				content:"\f132";
				
				
				
				
			}
		
	
		
			
			.icon--onedrive:before {
				content:"\f133";
				
				
				
				
			}
		
	
		
			
			.icon--paper-airplane:before {
				content:"\f134";
				
				
				
				
			}
		
	
		
			
			.icon--percentage:before {
				content:"\f135";
				
				
				
				
			}
		
	
		
			
			.icon--play:before {
				content:"\f136";
				
				
				
				
			}
		
	
		
			
			.icon--sandbox:before {
				content:"\f137";
				
				
				
				
			}
		
	
		
			
			.icon--signature--25:before {
				content:"\f138";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--signature:before {
				content:"\f139";
				
				
				
				
			}
		
	
		
			
			.icon--sort:before {
				content:"\f13a";
				
				
				
				
			}
		
	
		
			
			.icon--text--25:before {
				content:"\f13b";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--text-down:before {
				content:"\f13c";
				
				
				
				
			}
		
	
		
			
			.icon--text-up:before {
				content:"\f13d";
				
				
				
				
			}
		
	
		
			
			.icon--text:before {
				content:"\f13e";
				
				
				
				
			}
		
	
		
			
			.icon--tick:before {
				content:"\f13f";
				
				
				
				
			}
		
	
		
			
			.icon--trash--25:before {
				content:"\f140";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--trash:before {
				content:"\f141";
				
				
				
				
			}
		
	
		
			
			.icon--twitter:before {
				content:"\f142";
				
				
				
				
			}
		
	
		
			
			.icon--user--25:before {
				content:"\f143";
				
				@extend .icon--25;
				
				
				
				
			}
		
	
		
			
			.icon--user--add:before {
				content:"\f144";
				
				
				
				
			}
		
	
		
			
			.icon--user:before {
				content:"\f145";
				
				
				
				
			}
		
	
		
			
			.icon--users:before {
				content:"\f146";
				
				
				
				
			}
		
	


.icon--comment:before {
  content:"\f075";
  font: normal normal normal 14px/1 FontAwesome;
}

.icon--circle--delete:before {
  content:"\f111";
  border-radius: 50%;
}
