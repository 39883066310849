
.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.85);
	cursor: default;
	z-index: 1050;  // over magnific-popup and under notifications
  opacity: 1;

  &.ng-hide {
    pointer-events: none;
  }

  &__inner {
    height: 50%;
  }

  &__white{
    background: #fff;
  }

  .load-spinner {
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    width: 46px;
    height: 46px;
    min-height: 46px;
    animation: fadeIn .5s ease-in-out, flip 2s infinite;
    animation-delay: 0s, .8s;
    transition: background-color 1s linear;

    svg {
      margin-top: 5px;
      margin-left: 1px;
    }

  }

}
