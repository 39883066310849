/*
Rules

Horizontal rules

Markup:
<hr>

Styleguide base.rules
*/

hr {
	color: setting(colors, border);
	border: none;
	border-bottom: {
		width: 1px;
		style: solid;
	}
	margin-top: $b;
}
