hgroup,
ul, ol, dl,
blockquote, p, address,
h1, h2, h3, h4, h5, h6,
table,
fieldset, figure,
pre{
	margin-top: $b;
}

.margin-top {
  margin-top: $b!important;

  &-half {
    margin-top: $b/2!important;
  }
}

.margin-bottom {
  margin-bottom: $b!important;

  &-half {
    margin-bottom: $b/2!important;
  }
}

.margin-left {
  margin-left: $b!important;

  .rtl & {
    margin-left: inherit!important;
    margin-right: $b!important;
  }

  &-half {
    margin-left: $b/2!important;

    .rtl & {
      margin-left: inherit!important;
      margin-right: $b/2!important;
    }

  }
}

.margin-right {
  margin-right: $b!important;
  .rtl & {
    margin-right: inherit!important;
    margin-left: $b!important;
  }

  &-half {
    margin-right: $b/2!important;

    .rtl & {
      margin-right: inherit!important;
      margin-left: $b/2!important;
    }
  }
}

.padding-top {
	padding-top: $b!important;

  &-half {
    padding-top: $b/2!important;
  }
}

.padding-bottom {
  padding-bottom: $b!important;

  &-half {
    padding-bottom: $b/2!important;
  }
}

.padding-left {
  padding-left: $b!important;

  .rtl & {
    padding-left: inherit!important;
    padding-right: $b!important;
  }

  &-half {
    padding-left: $b/2!important;

    .rtl & {
      padding-left: inherit!important;
      padding-right: $b/2!important;
    }
  }
}

.padding-right {
  padding-right: $b!important;

  .rtl & {
    padding-right: inherit!important;
    padding-left: $b!important;
  }

  &-half {
    padding-right: $b/2!important;

    .rtl & {
      padding-right: inherit!important;
      padding-left: $b/2!important;
    }
  }
}

.pointer {
  cursor: pointer;
}
