/*
Align

Align text. You can also prefix the classes with breakpoints, such as `desk-align--left`.

Markup:
<div class="align {{modifier_class}}">I'm aligned</div>

.align--left - Align left
.align--center - Align center
.align--right - Align right

Styleguide trumps.align
*/

.align--left {
	text-align: left !important;
  .rtl & {
    text-align: right !important;
  }
}

.align--center {
	text-align: center !important;
}

.align--right {
	text-align: right !important;
  .rtl & {
    text-align: left !important;
  }
}

@each $breakpoint in setting(breakpoints) {

	$alias:     nth($breakpoint, 1);
	$condition: nth($breakpoint, 2);

	@include media-query($alias) {

		.#{$alias}-align {
			&--left {
				text-align: left !important;
			}

			&--right {
				text-align: right !important;
			}

			&--center {
				text-align: center !important;
			}
		}

	}

}
