/*
Value

Give special importance or value to (something) in speaking or writing.

Markup:
<strong>Strong importance</strong><br>
<em>Stress emphasis</em>

Styleguide base.values
*/

strong,
b {
	font-weight: setting(font-weights, bold);
	color: setting(colors, font-dark);
}
