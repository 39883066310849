/*
Alerts

Provide contextual feedback messages for typical user actions. Alerts don't have default classes, only base and modifier classes.

Markup:
<div class="alert {$modifiers}">Message</div>

.alert--success - Successful or positive
.alert--info - Informational
.alert--warning - Caution should be taken
.alert--error - errorous or potentially negative

Styleguide 17
*/

$alert: (
  width: 46%,
  left: 50%,
  top: 0
);

$alert-lap: (
  width: 60%,
  left: map_get($alert, left),
  top: map_get($alert, top)
);

$alert-palm: (
  width: 90%,
  left: map_get($alert, left),
  top: map_get($alert, top)
);

#messages {
  width: 100%;
}

.alert {
  padding: $b/2;
  padding-right: $b;
  border-radius: setting(border-radius);
  margin-top: $b;
  color: #fff;
  position: relative;

  a {
    color: inherit;
  }

  > :first-child {
    margin-top: 0;
  }

  &--success {
    background: setting(colors, secundary);
  }

  &--info {
    background: setting(colors, info);
  }

  &--warning {
    background: setting(colors, warning);
  }
  &--error {
    background: setting(colors, error);
  }

  .close--alert {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}

.alert--container {
  position: fixed;
  z-index: setting(z-index-notification);
  top: map_get($alert, top);
  left: map_get($alert, left);
  width: map_get($alert, width);
  margin-left: - map_get($alert, width) / 2;
  .rtl & {
    margin-left: inherit;
    margin-right: - map_get($alert, width) / 2;
  }

  @include media-query(palm) {
    width: map_get($alert-palm, width);
    margin-left: - map_get($alert-palm, width) / 2;
    .rtl & {
      margin-left: inherit;
      margin-right: - map_get($alert-palm, width) / 2;
    }
  }
  @include media-query(lap) {
    width: map_get($alert-lap, width);
    margin-left: - map_get($alert-lap, width) / 2;
    .rtl & {
      margin-left: inherit;
      margin-right: - map_get($alert-palm, width) / 2;
    }
  }
}




#messages {
  padding: 0;
}
