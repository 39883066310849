/*
Tooltipster

Tooltips are like hints, but initialized with JavaScript and with more inner content options.

Markup:
<div class="tooltipster-base">
	<div class="tooltipster-content">Prepare the document and specify the order of signing.</div>
	<div class="tooltipster-arrow {{modifier_class}}"><span></span></div>
</div>

.tooltipster-arrow-top - Top
.tooltipster-arrow-top-right - Top right
.tooltipster-arrow-top-left - Top left
.tooltipster-arrow-bottom - Bottom
.tooltipster-arrow-bottom-right - Bottom right
.tooltipster-arrow-bottom-left - Bottom left
.tooltipster-arrow-left - Left
.tooltipster-arrow-right - Right

Styleguide components.tooltipster
*/

span,
div,
button,
a,
p {
  &[sr-tooltip-content] {
    // template content to hide initially
    display: none;
  }
}

.tooltipster {

  &ed {
    cursor: pointer;
    display: inline-block;
  }

  &-base {
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: auto;
    overflow: visible;
    border-radius: setting(border-radius);
    z-index: setting(z-index-tooltip) !important;
    max-width: 340px;
    display: inline-block;
    color: #fff;

    .styleguide__example & {
      opacity: 1;
      position: relative;
    }
  }

  &-fade {
    opacity: 0;
    transition-property: opacity;

    &-show {
      opacity: 1;
    }
  }

  &-content {
    color: white;
    overflow: hidden;
    padding: $b/4 $b/2;
  }

  &-arrow {

  }
}

/*
Tooltipster - Color

Markup:
<div class="tooltipster-base {{modifier_class}}">
	<div class="tooltipster-content">Prepare the document and specify the order of signing.</div>
	<div class="tooltipster-arrow-top tooltipster-arrow"><span></span></div>
</div>

.tooltipster-success - Success
.tooltipster-info - Info
.tooltipster-warning - Warning
.tooltipster-error - Error
.tooltipster-interactive - Interactive

Styleguide components.tooltipster.color
*/

.tooltipster {

  &-success &-box{
    background-color: setting(colors, secundary);
    border-color: setting(colors, secundary);
  }

  &-info &-box{
    background-color: setting(colors, info);
    border-color: setting(colors, info);
  }

  &-warning &-box{
    background-color: setting(colors, warning);
    border-color: setting(colors, warning);
  }

  &-error &-box{
    background-color: setting(colors, error);
    border-color: setting(colors, error);
  }

  &-interactive {

    //.tooltipster {

      .tooltipster-box {
        background-color: #fff;
        color: setting(colors, font);
        box-shadow: setting(box-shadow);
        border: 1px solid #ccc;
        pointer-events: auto;
      }

      .tooltipster-content {
        color: inherit;

        h1, h2, h3, h4, h5 {
          margin-top: 0;
        }

      }

      &.tooltipster-bottom,
      &.tooltipster-bottom-right,
      &.tooltipster-bottom-left {

        .tooltipster-arrow {

          &-background {
            border-bottom-color: #fff;
            top: 1px;
          }

          &-border {
            border-bottom-color: #ccc;
          }

        }

      }

      &.tooltipster-top,
      &.tooltipster-top-right,
      &.tooltipster-top-left {

        .tooltipster-arrow {

          &-background {
            border-top-color: #fff;
            top: -1px;
          }

          &-border {
            border-top-color: #ccc;
          }

        }

      }

      &.tooltipster-right {

        .tooltipster-arrow {

          &-background {
            border-right-color: #fff;
            left: 1px;
          }

          &-border {
            border-right-color: #ccc;
          }

        }

      }

      &.tooltipster-left {

        .tooltipster-arrow {

          &-background {
            border-left-color: #fff;
            left: -1px;
          }

          &-border {
            border-left-color: #ccc;
          }

        }

      }

  }
}

/*
Tooltipster - Tools

Markup:
<div class="tooltipster-base tooltipster-interactive tooltip-tools">
	<div class="tooltipster-content">
		<a href="#" class="tooltipster-item"><i class="icon icon--signature--25"></i></a>
		<a href="#" class="tooltipster-item"><i class="icon icon--calendar--25"></i></a>
		<a href="#" class="tooltipster-item"><i class="icon icon--text--25"></i></a>
	</div>
	<div class="tooltipster-arrow-top tooltipster-arrow"><span></span></div>
</div>
<div class="tooltipster-base tooltipster-interactive">
	<div class="tooltipster-content">
		<a href="#" class="tooltipster-item"><i class="icon icon--text-down"></i></a>
		<a href="#" class="tooltipster-item"><i class="icon icon--text-up"></i></a>
		<a href="#" class="tooltipster-item"><i class="icon icon--calendar"></i></a>
	</div>
	<div class="tooltipster-arrow-bottom tooltipster-arrow"><span></span></div>
</div>

Styleguide components.tooltipster.tools
*/

.tooltipster {
  &-tools {
    .tooltipster-item + .tooltipster-item {
      margin-left: $b/4;
    }
  }
}

/*
Tooltipster - Menu

Markup:
<div class="tooltipster-base tooltipster-interactive tooltipster-menu">
	<div class="tooltipster-content">
		<div class="list-group list-group--nav">
			<a href="me-documents.html" class="list-group__item">
				<div class="flag">
					<div class="flag__object">
						<i class="icon icon--user"></i>
					</div>
					<div class="flag__body">
						<p><strong>Account settings</strong></p>
					</div>
				</div>
			</a>
			<a href="me-signatures.html" class="list-group__item">
				<div class="flag">
					<div class="flag__object">
						<i class="icon icon--signature"></i>
					</div>
					<div class="flag__body">
						<p><strong>Signatures</strong></p>
					</div>
				</div>
			</a>
			<a href="me-companies.html" class="list-group__item">
				<div class="flag">
					<div class="flag__object">
						<i class="icon icon--companies"></i>
					</div>
					<div class="flag__body">
						<p><strong>Companies</strong></p>
					</div>
				</div>
			</a>
			<a href="me-account.html" class="list-group__item">
				<div class="flag">
					<div class="flag__object">
						<i class="icon icon--lock"></i>
					</div>
					<div class="flag__body">
						<p><strong>Logout</strong></p>
					</div>
				</div>
			</a>
		</div>
	</div>
	<div class="tooltipster-arrow-top tooltipster-arrow"><span></span></div>
</div>

Styleguide components.tooltipster.menu
*/

.tooltipster {
  &-menu {
    .tooltipster-content {
      padding: 0;
    }
  }
}

/*
Tooltipster - Dialog

Markup:
<div class="tooltipster-base tooltipster-interactive tooltipster-dialog">
	<div class="tooltipster-content">
		<p><strong>Step 1 of 6. Click to add a document. Or drag and drop if you have an up to date browser.</strong></p>
		<p class="split">
			<div class="split__item">
				<a href="#" class="button button--link button--link--grey">Cancel</a>
			</div>
			<div class="split__item">
				<a href="#" class="button">Next</a>
			</div>
		</p>
	</div>
	<div class="tooltipster-arrow-top tooltipster-arrow"><span></span></div>
</div>

Styleguide components.tooltipster.dialog
*/

.tooltipster {
  &-dialog {
    .tooltipster-content {
      padding: $b;
      min-width: 320px;

      > :first-child {
        margin-top: 0;
      }
    }
  }
  &-item {
    + .tooltipster-item {
      margin-left: $b/4;
    }
  }
}

.help-step {
  position: relative;

  span {
    position: absolute;

    &.help-right {
      right: 0;

      .rtl & {
        left: 0;
        right: inherit;
      }

    }

    &.help-left {
      left: 0;

      .rtl & {
        right: 0;
        left: inherit;
      }

    }

    @include media-query(lap-and-down) {

      &.help-right {
        right: 50%;
      }

      &.help-left {
        left: 50%;
      }

    }

  }

}

sr-question {

  position: relative;

  label & {
    color: setting(colors, font);
  }

  i.icon:before {
    font-size: .95em;
  }
}

//button:not(.button--fluid), a.button:not(.button--fluid){
//  sr-question {
//    i.icon:first-child {
//      margin-right: 0!important;
//    }
//  }
//}
