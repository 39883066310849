/*
Spectrum

Markup:
<input type="color" value="#366cd8">

Styleguide components.spectrum
*/

.sp {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	z-index: z-index(spectrum);
	overflow: hidden;

	&-flat {
		position: relative;
	}

	&-top {
		position: relative;
		width: 100%;
		display: inline-block;
	}

	&-top-inner {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	&-color {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 20%;
		border-radius: setting(border-radius);
	}

	&-hue {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 84%;
		height: 100%;
		border-radius: setting(border-radius);
	}

	&-clear-enabled &-hue {
		top: 33px;
		height: 77.5%;
	}

	&-fill {
		padding-top: 80%;
	}

	&-sat,
	&-val {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&-alpha-enabled &-top {
		margin-bottom: 18px;
	}

	&-alpha-enabled &-alpha {
		display: block;
	}

	&-alpha-handle {
		position: absolute;
		top: -4px;
		bottom: -4px;
		width: 6px;
		left: 50%;
		cursor: pointer;
		border: 1px solid setting(colors, border);
		background: white;
		opacity: .8;
	}

	&-alpha {
		display: none;
		position: absolute;
		bottom: -14px;
		right: 0;
		left: 0;
		height: 8px;

		&-inner {
			border: 1px solid setting(colors, border);
		}
	}

	&-clear {
		display: none;

		&.sp-clear-display {
			background-position: center;
		}
	}

	&-container,
	&-replacer,
	&-preview,
	&-dragger,
	&-slider,
	&-alpha,
	&-clear,
	&-alpha-handle,
	&-dragging &-input,
	button {
		user-select: none;
	}

	&-input-disabled &-input-container,
	&-buttons-disabled &-button-container,
	&-palette-buttons-disabled &-palette-button-container,
	&-palette-only &-picker-cintainer,
	&-palette-disabled &-palette-container,
	&-initial-disabled &-initial {
		display: none;
	}

	&-sat {
		@include background-image(linear-gradient(left, #fff 0%, rgba(204, 154, 129, 0) 100%));
	}

	&-val {
		@include background-image(linear-gradient(bottom, #000 0%, rgba(204, 154, 129, 0) 100%));
	}

	&-hue {
		@include background-image(linear-gradient(top, red 0%, yellow 17%, lime 33%, cyan 50%, blue 67%, magenta 83%, red 100%));
	}

	&-1 {
		height: 17%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
	}

	&-2 {
		height: 16%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
	}

	&-3 {
		height: 17%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
	}

	&-4 {
		height: 17%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
	}

	&-5 {
		height: 16%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
	}

	&-6 {
		height: 17%;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
	}

	&-hidden {
		display: none !important;
	}

	&-cf {
		@extend .clearfix;
	}

	@include media-query(lap-and-up) {
		&-color {
			right: 40%;
		}

		&-hue {
			left: 63%;
		}

		&-fill {
			padding-top: 60%;
		}
	}

	&-dragger,
	&-slider {
		position: absolute;;
		border: 1px solid #fff;
		cursor: pointer;
		top: 0;
		box-shadow: 0 1px 2px rgba(0,0,0,.2);
	}

	&-dragger {
		border-radius: 100%;
		height: $b/2;
		width: $b/2;
		left: 0;
	}

	&-slider {
		border-radius: $b/8;
		height: $b/4;
		left: -1px;
		right: -1px;
	}

	&-container {
		border-radius: setting(border-radius);
		border: 1px solid setting(colors, border);
		background: #fff;
		padding: $b/2;
	}

	&-input {
		@extend .input--text;
	}

	&-initial {
		float: left;
		border-radius: setting(border-radius);
		overflow: hidden;
		margin: $b/2 0;
    .rtl & {
      float: right;
    }
		span {
			width: $b;
			height: $b;
			border: none;
			display: block;
			float: left;
			margin: 0;
      .rtl & {
        float: right;
      }
		}
	}

	&-palette-button-container,
	&-button-container {
		float: right;
    margin: $b/2 0;
    .rtl & {
      float: left;
    }
	}

	&-replacer {
		overflow: hidden;
		cursor: pointer;
		vertical-align: middle;
	}

	&-dd {
		display: none;
	}

	&-preview {
		margin-right: $b/2;

		&-inner {
			display: block;
			width: $b*2;
			height: $b*2;
			border-radius: setting(border-radius);
		}
	}

	&-choose {
		margin-left: $b/4;
	}

}
