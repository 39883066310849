/*
Containers

Containers wrap the inner content of most sections.

Markup:
<div class="sr-container {{modifier_class}}">
	<p>Lorem ipsum dolor sit amet</p>
</div>

.sr-container--narrow - Narrow container
.sr-container--flush - Remove padding

Styleguide components.container
*/

.sr-container {
	margin: 0 auto;
	padding: 0 $b*0.75;
        max-width: 1280px;

        .mobile & {
          max-width: 100vw;
        }
	&--fluid {
		max-width: none;
	}

	&--flush {
		padding: 0;
	}
}

@include media-query(desk) {
	.sr-container--narrow {
		width: percentage(2/3);
		max-width: 960px;
	}

}
