/*
Breadcrumbs

Markup:
<ul class="breadcrumbs">
	<li><a href="#">Lorem</a></li>
	<li><a href="#">Ipsum</a></li>
	<li>Dolor</li>
</ul>

Styleguide components.breadcrumbs
*/

.breadcrumbs {
	list-style-type: none;
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;

		+ li:before {
			content:"\002F" "\00A0";
			padding: 0 3px;
		}

		&:first-child {
			font-weight: setting(font-weights, bold);
		}
	}
}
