
.lighter-font, .font-light {
  color: setting(colors, font-light);
}

.darker-font, .font-dark {
  color: setting(colors, font-dark);
}

.extra-line-height {
  line-height: 2em;
}

.cap-overflow {
  &, &__item, &__item * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__item, &__item * {
    width: 100%
  }
}
