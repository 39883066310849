/*
Navs

Markup:
<ul class="nav {{modifier_class}}">
	<li><a href="#">Lorem</a></li>
	<li><a href="#">Ipsum</a></li>
	<li><a href="#">Dolor</a></li>
	<li><a href="#">Sit</a></li>
	<li><a href="#">Amet</a></li>
</ul>

.nav--inline - Horizontal nav
.nav--right - Right aligned nav
.nav--inline.nav--right - Right align, horizontal nav

Styleguide objects.nav
*/

.nav {
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    display: block;
    .site__footer & {
      display: inline-block;
    }
  }

  .icon {
    color: setting(colors, font-light);
  }
}

.nav--inline {
  // margin-left: $b/2*-1;

  > li {
    display: inline-block;

    + li {
      margin-left: $b;
    }
  }

  @include media-query(palm) {
    > li + li {
      margin-left: $b/2;
    }
  }
}

.nav--right {
  text-align: right;
}

.nav--inline.nav--right {
  margin-left: 0;
  margin-right: $b/2 * -1;
}
