/*
Smalls

Small print

Markup:
<small>By submitting this form, I agree to <a href="#">the terms and conditions</a>.</small>

Styleguide base.smalls
*/

small,
.small {
	@include font-size(13);
}
