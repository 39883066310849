
/* override loading bar colors */

#loading-bar .bar {
  background: setting(colors, primary);
}

/* Fancy blur effect */
#loading-bar .peg {
  box-shadow: setting(colors, primary) 1px 0 6px 1px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: setting(colors, primary);
  border-left-color: setting(colors, primary);
}
