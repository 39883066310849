@mixin media-query($media-query) {

	$breakpoint-found: false;

	@each $breakpoint in setting(breakpoints) {

		$alias: nth($breakpoint, 1);
		$condition: nth($breakpoint, 2);

		@if $media-query == $alias and $condition {
			$breakpoint-found: true;

			@media #{$condition} {
				@content;
			}
		}

	}

	@if $breakpoint-found == false {
		@warn "Oops! Breakpoint '#{$media-query}' does not exist."
	}
}
