/*
Headings

Headings 1-6's corresponding abstract classes for double-stranded heading hierarchy. Use these helper classes to cause other elements to adopt the styling of the respective heading.

Markup:
<h2 class="alpha">Heading 2 adopts style of h1</h2>
<h1 class="gamma">Heading 1 adopts style of h3</h2>

Styleguide trumps.headings
*/

.alpha {
	@extend h1;
}

.beta {
	@extend h2;
}

.gamma {
	@extend h3;
}

.delta {
	@extend h4;
}

@include media-query(lap-and-up) {

	.alpha {
		@include font-size(28, setting(line-height)*1.5/28);
	}

	.beta {
		@include font-size(18);
	}

}
