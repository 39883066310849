.badge {
 display: inline-block;
  padding: .25em .6em;
  font-size: 86%;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10rem;
  font-weight: normal;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  &.yellow {
    background-color: setting(colors, warning);;
    color: #fff;
  }
}
