/*
Tables

Because some plugins still use tables for layout, we use our own custom .table class to style tables

Markup:
<table class="table">
	<tbody>
		<tr>
			<td data-title="Sit">Lorem</td>
			<td data-title="Amet">ipsum</td>
			<td data-title="Consectetur">dolor</td>
		</tr>
		<tr>
			<td data-title="Sit">Lorem</td>
			<td data-title="Amet"></td>
			<td data-title="Consectetur">dolor</td>
		</tr>
		<tr>
			<td data-title="Sit">Lorem</td>
			<td data-title="Amet"></td>
			<td data-title="Consectetur">dolor</td>
		</tr>
	</tbody>
	<thead>
		<tr>
			<th>Sit</th>
			<th>Amet</th>
			<th>Consectetur</th>
		</tr>
	</thead>
	<tfoot>
		<tr>
			<th>Sit</th>
			<th>Amet</th>
			<th>Consectetur</th>
		</tr>
	</tfoot>
</table>

Styleguide objects.table
*/

.table {
	width: 100%;

	thead {
		text-align: left;
		border-bottom: 1px solid setting(colors, border-color);

		th {
			font-weight: setting(font-weights, bold);
			color: setting(colors, grey-1);
		}
	}

	tbody {
		text-align: left;

		tr:nth-child(even) {
			background: setting(colors, grey-6);
		}
	}
	tfoot {
		text-align: left;
		border-top: 1px solid setting(colors, border-color);

		th {
			font-weight: setting(font-weights, bold);
			color: setting(colors, grey-1);
		}
	}
	td, th {
		padding: 4px $b/4;
	}
}

@include media-query(desk) {

	.table {
		&, thead, tbody, th, td, tr {
			display: block;
		}

		thead tr,
		tfoot tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tbody {
			td:before,
			th:before {
				display: inline-block;
				position: absolute;
				left: $b/4;
				top: 4px;
				padding-right: $b/2;
				white-space: nowrap;
				content: attr(data-title);
				font-weight: setting(font-weights, bold);
			}
			tr {
				border-bottom: 1px solid setting(colors, border-color);
			}
			td:empty,
			th:empty {
				display: none;
			}
			td {
				border: none;
				// border-bottom: 1px solid #eee;
				position: relative;
				padding-left: 50%;
			}
		}

	}

}
