/*
Placeholder

Markup:
<div href="#" class="placeholder {{modifier_class}}">Signature</div>

.placeholder--1 - Placeholder for contact 1
.placeholder--2 - Placeholder for contact 2
.placeholder--3 - Placeholder for contact 3
.placeholder--4 - Placeholder for contact 4
.placeholder--5 - Placeholder for contact 5
.placeholder--6 - Placeholder for contact 6
.placeholder--7 - Placeholder for contact 7
.placeholder--8 - Placeholder for contact 8
.placeholder--9 - Placeholder for contact 9
.placeholder--10 - Placeholder for contact 10
.placeholder--11 - Placeholder for contact 11

Styleguide components.placeholder
*/

.placeholder {

	position: absolute;
	border-width: 1px;
	border-style: solid;
	border-color: currentColor;
	color: setting(colors, primary);
	top: 0;
	left: 0;
	z-index: z-index(placeholder);
	display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.ng-click-active {
    background-color: rgba(setting(colors, primary), 0.3);
  }

  &--text {

    .type_text {
      margin-left: $b/4;

    }

    .icon {
      display: inline-block;
      //padding: 3px 0 0 3px;
    }

  }

  &.editable {

    &.active,
    &:hover {

      &:before {
        content: "";
        position: absolute;
        border-style: dashed;
        border-color: #999999;
        border-width: 1px 0;
        top: -1px;
        right: -9999px;
        right: -100vw;
        bottom: -1px;
        left: -9999px;
        left: -100vw;
        pointer-events: none;
      }

      &:after {
        content: "";
        position: absolute;
        border-style: dashed;
        border-color: #999999;
        border-width: 0 1px;
        top: -9999px;
        top: -100vw;
        right: -1px;
        bottom: -9999px;
        bottom: -100vw;
        left: -1px;
        pointer-events: none;
      }
    }
  }

	.styleguide__example & {
		position: relative;
		top: auto;
		left: auto;
	}
}

.placeholder .placeholder--background::before {
  display: block;
  content: '';
  background: currentColor;
  opacity: 0.1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.placeholder:hover .placeholder--background::before {
  opacity: 0.2;
}
.img--wrapper.ng-click-active .placeholder--background::before {
  opacity: 0.3;
}
