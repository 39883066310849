/* angular animations */

.animate-fade {
  display: block!important;
  opacity: 1;
  transition: opacity .5s linear;

  &.ng-hide-remove-active {
    //animation: fadeIn .5s;
  }

  &.ng-hide-add-active {
    //animation: fadeOut .5s;
  }

  &.ng-hide {
    &:not(.ng-hide-add-active), &:not(.ng-hide-remove-active){
      opacity: 0;
      //display: none!important;
    }
  }

}


.animate-fade-down.ng-enter {
  animation: fadeInDown .5s;
}

.animate-fade-up.ng-enter {
  animation: fadeInUp .5s;
}


.animate-fade-down.ng-leave {
  animation: fadeOutDown .5s;
}

///* The starting CSS styles for the enter animation */
//.animate-fade-down.ng-enter {
//  transition:0.5s linear all;
//  opacity:0;
//}
//
///* The finishing CSS styles for the enter animation */
//.animate-fade-down.ng-enter.ng-enter-active {
//  opacity:1;
//}

.dot {
  &.one, &.two, &.three {
    animation: dot 1.3s infinite;
    opacity: 0;
  }
  &.one {
    animation-delay: .0s;
  }
  &.two {
    animation-delay: .2s;
  }
  &.three {
    animation-delay: .3s;
  }
}

@keyframes dot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
