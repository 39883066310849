/*
Selectize

No styleguide reference
*/

$selectize-font-family: inherit;
$selectize-font-smoothing: inherit;
$selectize-font-size: setting(font-size) * 1px;
$selectize-line-height: setting(line-height) * 1px;

$selectize-color-text: setting(colors, font);
$selectize-color-border: setting(colors, border);
$selectize-color-highlight: setting(colors, primary);
$selectize-color-input: #fff;
$selectize-color-input-full: $selectize-color-input;
$selectize-color-disabled: #fafafa;
$selectize-color-item: lighten(setting(colors, primary), 40);
$selectize-color-item-text: setting(colors, font);
$selectize-color-item-border: transparent;
$selectize-color-item-active: darken(setting(colors, primary), 10);
$selectize-color-item-active-text: #fff;
$selectize-color-item-active-border: transparent;
$selectize-color-dropdown: #fff;
$selectize-color-dropdown-border: $selectize-color-border;
$selectize-color-dropdown-border-top: $selectize-color-border;
$selectize-color-dropdown-item-active: lighten(setting(colors, primary), 40);
$selectize-color-dropdown-item-active-text: setting(colors, font);
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), .5);
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text;
$selectize-color-optgroup: $selectize-color-dropdown;
$selectize-color-optgroup-text: $selectize-color-text;
$selectize-lighten-disabled-item: 30%;
$selectize-lighten-disabled-item-text: 30%;
$selectize-lighten-disabled-item-border: 30%;
$selectize-opacity-disabled: 0.5;

$selectize-shadow-input: inset 0 1px 2px rgba(0,0,0,.05), 0 -1px 1px #fff, 0 1px 0 #fff;
$selectize-shadow-input-focus: 0px 0px 3px 0px rgba(map-get($forms, border-color-focus), 0.20);
$selectize-border: 1px solid $selectize-color-border;
$selectize-border-radius: setting(border-radius);

$selectize-width-item-border: 0;
$selectize-max-height-dropdown: $b*8;

$selectize-padding-x: 12px;
$selectize-padding-y: 7px;
$selectize-padding-item-x: 8px;
$selectize-padding-item-y: 2px;
$selectize-padding-dropdown-item-x: $selectize-padding-x;
$selectize-padding-dropdown-item-y: $b/4;
$selectize-margin-item-x: 6px;
$selectize-margin-item-y: 3px;

$selectize-arrow-size: 5px;
$selectize-arrow-color: #808080;
$selectize-arrow-offset: 15px;

$selectize-caret-margin: 0 2px 0 0;
$selectize-caret-margin-rtl: 0 4px 0 -2px;

@import "node_modules/selectize-scss/src/selectize.scss";

.selectize-control {
	//margin: 4px 0;
	text-align: left;

	&.multi {
		.selectize-input {
			> div {
				border-radius: setting(border-radius);
			}
		}
	}
}

.selectize-dropdown {
  [data-selectable] {
    .highlight {
        color: $selectize-color-item-active-text;
    }
    .rtl & {
      direction: ltr;
      text-align: right;
    }
  }
  &.margin-top {
    margin-top: -1px!important;
  }
}

.selectize-input {

	z-index: z-index(selectize);

	> input {
		padding: 9px 0.85714em;
	}
}

.rtl .selectize-control {
  text-align: right;

  &.single .selectize-input:after {
    left: $selectize-arrow-offset;
    right: auto;
  }
  .selectize-input > input {
    margin: $selectize-caret-margin-rtl !important;
  }
}
