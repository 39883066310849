/*
Lists

Use ordered and unordered markup list.

Markup:
<ul class="list {{modifier_class}}">
	<li>Lorem</li>
	<li>ipsum</li>
	<li>dolor</li>
</ul>

.list--inline - Items side by side

Styleguide objects.list
*/

.list {

	&,
	ul,
	ol {
		list-style-type: none;
	}
}

.list--inline {

	> li {
		display: inline-block;
		margin-left: $b;

		&:first-child {
			margin-left: 0;
		}
	}
	img {
		margin-top: 0;
	}
}
