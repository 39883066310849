
pre.debug {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #cdcdcd;
  text-align: left;
  padding: 5px;
  color: #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

pre.code {
  background-color: #cdcdcd;
  text-align: left;
  padding: 5px;
  color: #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;  
}

