/*
Droparea

Markup:
<div class="droparea">
	<p>Drag and drop files here<br>
		or<br>
	<a href="#" class="button">Choose file to upload</a></p>
</div>

Styleguide components.droparea
*/

.droparea {
	border: 1px dashed setting(colors, border);
	padding: 0 $b 0 $b;
	border-radius: setting(border-radius);
	text-align: center;
	margin-top: $b;

  &.file-hover {

    border-color: setting(colors, primary);

    .draganddrop {
      display: none;

      &--release {
        color: setting(colors, primary);
        display: block;

        .modal:not(.modal--invert) & {
          color: #fff;
        }

      }

    }

    .modal:not(.modal--invert) & {
      border-color: #fff;
    }

  }

  .draganddrop--release {
    display: none;
  }

}

