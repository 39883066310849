/*
System Status Component
*/

.sr-status {

  svg {

    padding: 1px;
    margin-right: 5px;
    overflow: visible;

    .rtl & {
      margin-left: 5px;
    }

    circle {
      fill: setting(colors, font-light);
    }

  }

  &.none {
    svg circle {
      fill: setting(colors, ok);
    }
  }

  &.minor {
    svg circle {
      fill: setting(colors, warning);
    }
  }

  &.maintenance {
    svg circle {
      fill: setting(colors, info);
    }
  }

  &.major, &.critical {
    svg circle {
      fill: setting(colors, error);
    }
  }

}
