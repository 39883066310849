.list {
  margin-top: 0px;

  &--included,
  &--excluded {
    list-style: none;

    li {
      position: relative;
      padding-left: 30px;
      text-align: left;

      &:before {
        @extend .icon:before;

        position: absolute;
        left: 0;
        top: 0;
        margin-top: 5px;
      }

      + li {
        margin-top: $b/2;
      }
    }
    + .list {
      &--excluded {
        margin-top: $b/2;
      }
    }
  }

  &--included {
    li:before {
      @extend .icon--tick:before;
      font-size: 24px;
      line-height: 24px;
      display: inline-block;
      margin: -1px 0 0 0;

      color: setting(colors, success);
    }
  }

  &--excluded {
    li:before {
      @extend .icon--circle--cross:before;

      color: setting(colors, error);
    }
  }
}
