.pagination {
  height: 36px;
  margin: 5px 0;
  ul {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */
    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
  }
  li {
    display: inline;
    cursor: pointer;
  }
  a {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 1px;
    .rtl & {
      float: right;
      //border-left-width: 0;
      //border-right-width: 1px;
    }
    &:hover {
      background-color: #f5f5f5;
    }
  }

  a.offset {
    padding: 0 8px;
    line-height: 20px;
  }
  .active a {
    background-color: #f5f5f5;
    color: #999999;
    cursor: default;
  }
  .disabled {
    span {
      color: #999999;
      background-color: transparent;
      cursor: default;
    }
    a {
      color: #999999;
      background-color: transparent;
      cursor: default;
      &:hover {
        color: #999999;
        background-color: transparent;
        cursor: default;
      }
    }
  }
  li {
    & a {
      border-left-width: 0;
      border-right-width: 1px;
      .rtl & {
        border-left-width: 1px;
        border-right-width: 0;
      }
    }
    &:first-child a {
      border-left-width: 1px;
      border-radius: 3px 0 0 3px;
      .rtl & {
        border-right-width: 1px;
        border-radius: 0 3px 3px 0;
      }
    }
    &:last-child a {
      border-right-width: 1px;
      border-radius: 0 3px 3px 0;
      .rtl & {
        border-left-width: 1px;
        border-right-width: 0;
        border-radius: 3px 0 0 3px;
      }
    }
  }
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
  .rtl & {
    text-align: left;
  }
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  *zoom: 1;
  &:before {
    display: table;
    content: "";
  }
  &:after {
    display: table;
    content: "";
    clear: both;
  }
  li {
    display: inline;
  }
  a {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
  .next a {
    float: right;
    .rtl & {
      float: left;
    }
  }
  .previous a {
    float: left;
    .rtl & {
      float: right;
    }
  }
  .disabled a {
    color: #999999;
    background-color: #fff;
    cursor: default;
    &:hover {
      color: #999999;
      background-color: #fff;
      cursor: default;
    }
  }
}
