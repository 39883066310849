/*
Flush

Remove margins

Markup:
<p class="flush {{modifier_class}}">I'm flushed</p>

.flush--top - Flush top
.flush--bottom - Flush bottom

Styleguide trumps.flush
*/

.flush {
	&--top {
		margin-top: 0 !important;
	}

	&--bottom {
		margin-bottom: 0 !important;
	}
}
