/*
Leads

Intro text

Markup:
<p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer venenatis lorem at molestie ornare. Mauris condimentum ac sem tempor facilisis. Praesent nibh sem, facilisis non varius consectetur, ultricies eu diam.</p>

Styleguide components.lead
*/

.lead {
	@include font-size(16);
  color: setting(colors, font);
  font-weight: setting(font-weights, normal);
}

@include media-query(lap) {
	.lead {
		@include font-size(18, setting(line-height)*1.25/18);
	}
}


@include media-query(desk) {
	.lead {
		@include font-size(20, setting(line-height)*1.5/20);
	}
}
