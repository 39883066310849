/*
Truncate

Markup:
<p class="truncate">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum condimentum ipsum, ac viverra turpis bibendum at. Morbi ac nisl eget odio imperdiet vulputate eget lobortis neque. Fusce sed nulla massa.</p>

Styleguide trumps.truncate
*/

.truncate {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
