/*
Modals

Modals are streamlined, but flexible, dialog prompts with the minimum required functionality and smart defaults.

Markup:
<a href="#modal-text" class="open-modal">Modal text</a>
<section class="modal mfp-hide" id="modal-text" tabindex="-1" role="dialog" aria-labelledby="modal-label" aria-hidden="true">
	<div class="modal__inner">
		<div class="modal__body">
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquam ut eros ut elementum. Proin iaculis dapibus commodo. Vivamus dapibus sed felis sed semper. Proin eget pulvinar ipsum. Integer consectetur faucibus nulla in vehicula. Ut purus magna, ultrices non ullamcorper sit amet, aliquam nec quam. Pellentesque sagittis tempus metus eget ultrices. Proin dignissim id lectus mattis convallis.</p>
			<p>Nunc tristique interdum ipsum vel mattis. Integer lacinia ante vitae nulla mollis sodales. Suspendisse a tellus urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lobortis risus vel mauris pharetra consequat. Ut vehicula id massa non rutrum. Donec nisi nisi, dignissim quis mauris nec, hendrerit scelerisque eros. In ac lobortis nunc. In nec nisl a tortor sagittis cursus a non enim. Quisque egestas, massa at aliquam porttitor, enim augue ullamcorper purus, at faucibus lorem ante nec justo. Praesent dui turpis, hendrerit vel est et, lobortis hendrerit elit.</p>
			<p>Morbi mollis nulla quis nulla viverra, quis adipiscing magna faucibus. Donec eu massa ac eros interdum ornare. Sed interdum consectetur eleifend. Integer et cursus purus. Pellentesque dictum orci diam, nec viverra elit bibendum eget. Sed felis sem, adipiscing id ullamcorper ut, tempus non ipsum. Sed ac molestie nisi, id convallis turpis.</p>
			<p>Pellentesque malesuada, elit vitae pulvinar suscipit, purus erat cursus ante, sed convallis diam est sagittis mi. Sed in lacus convallis, vehicula orci sed, semper diam. Etiam semper ut sapien at posuere. Etiam lacus justo, sollicitudin id lectus sed, molestie sagittis enim. Proin erat lectus, elementum at nisi quis, blandit cursus enim. Sed nec magna id justo molestie viverra sit amet sed est. Duis consectetur ullamcorper arcu sit amet condimentum. Duis ut nunc odio. Curabitur sodales imperdiet eleifend. Praesent malesuada odio mi, lobortis convallis eros malesuada non. Curabitur a justo ut mi pellentesque imperdiet.</p>
			<p>Vivamus ligula magna, facilisis vel vehicula vel, vulputate aliquam nunc. Proin massa metus, auctor eget laoreet non, facilisis eu ligula. Donec odio ipsum, elementum a mauris eu, rutrum lobortis risus. Nulla facilisi. Nullam rutrum quis purus vitae molestie. Vestibulum arcu massa, molestie non tortor a, hendrerit mattis lectus. Aliquam orci tortor, semper vitae dolor quis, suscipit vestibulum urna.</p>
		</div>
	</div>
</section>

Styleguide components.modal
*/

.modal {
	position: relative;
	width: auto;
	margin: $b auto;
	background: setting(colors, primary);
	color: #fff;
	max-width: $b*35;
	border-radius: setting(border-radius);
	padding: $b/2;
	// text-align: center;
	border-color: #fff;
	outline: none !important;

  &, &__body {
    > :first-child {
      margin-top: 0;
    }
  }

  &--no-user-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

	h1, h2, h3, h4,
	a,
	strong,
	p,
  span,
	hr {
		color: inherit;
	}

  .status {
    position: absolute;
    top: $b/4;
    text-align: center;
    left: -$b/16;
    width: 100%;
  }

  .checkbox span {
		color: #fff;
	}

  a {
    text-decoration: underline;
  }

  .form--error {
    font-size: x-small;
  }

  .csv_errors{
    overflow-y: auto;
    max-height: 130px;
    ul {
      margin-top: 2px;
    }

  }




	// .list-group {
	// 	margin-top: $b;
	// 	text-align: left;

	// 	&__item {
	// 		border-color: rgba(255,255,255,0.25);
	// 		padding-left: 0;
	// 		padding-right: 0;

	// 		&:after {
	// 			color: rgba(255,255,255,0.5);
	// 		}

	// 		&:last-child {
	// 			border: none;
	// 		}

	// 		&:hover {
	// 			&:after {
	// 				color: rgba(255,255,255,0.75);
	// 			}
	// 		}
	// 	}
	// }

	&--invert {
		background: #fff;
		color: setting(colors, font);

		h1, h2, h3, h4 {
			color: setting(colors, font-dark);
		}

    a {
      text-decoration: none;
      color: setting(colors, primary);
    }

    span, .checkbox span {
      color: inherit;
    }


    .button {
			// color: #fff;
		}
	}

	&--narrow {
		max-width: $b*20;
	}

	&--wide {
		max-width: $b*45;
	}


	@include media-query(lap-and-up) {

		padding: $b;

	}


}

// .has-modal {
// 	overflow: hidden;
// }

/* Magnific Popup CSS */

//@import "settings";

////////////////////////
//
// Contents:
//
// 1. Default Settings
// 2. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 3. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



//////////////////////////
//// 1. Default Settings
//////////////////////////
//
//$mfp-overlay-color:                   #0b0b0b !default;
//$mfp-overlay-opacity:                 0.8 !default;
//$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // shadow on image or iframe
//$mfp-popup-padding-left:              8px !default; // Padding from left and from right side
//$mfp-popup-padding-left-mobile:       6px !default; // Same as above, but is applied when width of window is less than 800px
//
//$mfp-z-index-base:                    1040 !default; // Base z-index of popup
//$mfp-include-arrows:                  true !default; // include styles for nav arrows
//$mfp-controls-opacity:                0.65 !default;
//$mfp-controls-color:                  #FFF !default;
//$mfp-controls-border-color:           #3F3F3F !default;
//$mfp-inner-close-icon-color:          #333 !default;
//$mfp-controls-text-color:             #CCC !default; // Color of preloader and "1 of X" indicator
//$mfp-controls-text-color-hover:       #FFF !default;
//$mfp-IE7support:                      true !default; // Very basic IE7 support
//
//// Iframe-type options
//$mfp-include-iframe-type:             true !default;
//$mfp-iframe-padding-top:              40px !default;
//$mfp-iframe-background:               #000 !default;
//$mfp-iframe-max-width:                900px !default;
//$mfp-iframe-ratio:                    9/16 !default;
//
//// Image-type options
//$mfp-include-image-type:              true !default;
//$mfp-image-background:                #444 !default;
//$mfp-image-padding-top:               40px !default;
//$mfp-image-padding-bottom:            40px !default;
//$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom
//
//// Image caption options
//$mfp-caption-title-color:             #F3F3F3 !default;
//$mfp-caption-subtitle-color:          #BDBDBD !default;
//
//// A11y
//$mfp-use-visuallyhidden:              false !default; // Hide content from browsers, but make it available for screen readers
//
//
//
//////////////////////////
//// 2. General styles
//////////////////////////
//
//// Transluscent overlay
//.mfp-bg {
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  z-index: $mfp-z-index-base + 2;
//  overflow: hidden;
//  position: fixed;
//
//  background: $mfp-overlay-color;
//  opacity: $mfp-overlay-opacity;
//  @if $mfp-IE7support {
//    filter: unquote("alpha(opacity=#{$mfp-overlay-opacity*100})");
//  }
//}
//
//// Wrapper for popup
//.mfp-wrap {
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  z-index: $mfp-z-index-base + 3;
//  position: fixed;
//  outline: none !important;
//  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
//}
//
//// Root container
//.mfp-container {
//  text-align: center;
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  left: 0;
//  top: 0;
//  padding: 0 $mfp-popup-padding-left;
//  -webkit-box-sizing: border-box;
//  -moz-box-sizing: border-box;
//  box-sizing: border-box;
//}
//
//// Vertical centerer helper
//.mfp-container {
//  &:before {
//    content: '';
//    display: inline-block;
//    height: 100%;
//    vertical-align: middle;
//  }
//}
//
//// Remove vertical centering when popup has class `mfp-align-top`
//.mfp-align-top {
//  .mfp-container {
//    &:before {
//      display: none;
//    }
//  }
//}
//
//// Popup content holder
//.mfp-content {
//  position: relative;
//  display: inline-block;
//  vertical-align: middle;
//  margin: 0 auto;
//  text-align: left;
//  z-index: $mfp-z-index-base + 5;
//}
//.mfp-inline-holder,
//.mfp-ajax-holder {
//  .mfp-content {
//    width: 100%;
//    cursor: auto;
//  }
//}
//
//// Cursors
//.mfp-ajax-cur {
//  cursor: progress;
//}
//.mfp-zoom-out-cur {
//  &, .mfp-image-holder .mfp-close {
//    cursor: -moz-zoom-out;
//    cursor: -webkit-zoom-out;
//    cursor: zoom-out;
//  }
//}
//.mfp-zoom {
//  cursor: pointer;
//  cursor: -webkit-zoom-in;
//  cursor: -moz-zoom-in;
//  cursor: zoom-in;
//}
//.mfp-auto-cursor {
//  .mfp-content {
//    cursor: auto;
//  }
//}
//
//.mfp-close,
//.mfp-arrow,
//.mfp-preloader,
//.mfp-counter {
//  -webkit-user-select:none;
//  -moz-user-select: none;
//  user-select: none;
//}
//
//// Hide the image during the loading
//.mfp-loading {
//  &.mfp-figure {
//    display: none;
//  }
//}
//
//// Helper class that hides stuff
//@if $mfp-use-visuallyhidden {
//  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
//  .mfp-hide {
//    border: 0 !important;
//    clip: rect(0 0 0 0) !important;
//    height: 1px !important;
//    margin: -1px !important;
//    overflow: hidden !important;
//    padding: 0 !important;
//    position: absolute !important;
//    width: 1px !important;
//  }
//} @else {
//  .mfp-hide {
//    display: none !important;
//  }
//}
//
//
//////////////////////////
//// 3. Appearance
//////////////////////////
//
//// Preloader and text that displays error messages
//.mfp-preloader {
//  color: $mfp-controls-text-color;
//  position: absolute;
//  top: 50%;
//  width: auto;
//  text-align: center;
//  margin-top: -0.8em;
//  left: 8px;
//  right: 8px;
//  z-index: $mfp-z-index-base + 4;
//  a {
//    color: $mfp-controls-text-color;
//    &:hover {
//      color: $mfp-controls-text-color-hover;
//    }
//  }
//}
//
//// Hide preloader when content successfully loaded
//.mfp-s-ready {
//  .mfp-preloader {
//    display: none;
//  }
//}
//
//// Hide content when it was not loaded
//.mfp-s-error {
//  .mfp-content {
//    display: none;
//  }
//}
//
//// CSS-reset for buttons
//button {
//  &.mfp-close,
//  &.mfp-arrow {
//    overflow: visible;
//    cursor: pointer;
//    background: transparent;
//    border: 0;
//    -webkit-appearance: none;
//    display: block;
//    outline: none;
//    padding: 0;
//    z-index: $mfp-z-index-base + 6;
//    -webkit-box-shadow: none;
//    box-shadow: none;
//  }
//  &::-moz-focus-inner {
//    padding: 0;
//    border: 0
//  }
//}
//
//
//// Close icon
//.mfp-close {
//  width: 44px;
//  height: 44px;
//  line-height: 44px;
//
//  position: absolute;
//  right: 0;
//  top: 0;
//  text-decoration: none;
//  text-align: center;
//  opacity: $mfp-controls-opacity;
//  @if $mfp-IE7support {
//    filter: unquote("alpha(opacity=#{$mfp-controls-opacity*100})");
//  }
//  padding: 0 0 18px 10px;
//  color: $mfp-controls-color;
//
//  font-style: normal;
//  font-size: 28px;
//  font-family: Arial, Baskerville, monospace;
//
//  &:hover,
//  &:focus {
//    opacity: 1;
//    @if $mfp-IE7support {
//      filter: unquote("alpha(opacity=#{1*100})");
//    }
//  }
//
//  &:active {
//    top: 1px;
//  }
//}
//.mfp-close-btn-in {
//  .mfp-close {
//    color: $mfp-inner-close-icon-color;
//  }
//}
//.mfp-image-holder,
//.mfp-iframe-holder {
//  .mfp-close {
//    color: $mfp-controls-color;
//    right: -6px;
//    text-align: right;
//    padding-right: 6px;
//    width: 100%;
//  }
//}
//
//// "1 of X" counter
//.mfp-counter {
//  position: absolute;
//  top: 0;
//  right: 0;
//  color: $mfp-controls-text-color;
//  font-size: 12px;
//  line-height: 18px;
//  white-space: nowrap;
//}
//
//// Navigation arrows
//@if $mfp-include-arrows {
//  .mfp-arrow {
//    position: absolute;
//    opacity: $mfp-controls-opacity;
//    @if $mfp-IE7support {
//      filter: unquote("alpha(opacity=#{$mfp-controls-opacity*100})");
//    }
//    margin: 0;
//    top: 50%;
//    margin-top: -55px;
//    padding: 0;
//    width: 90px;
//    height: 110px;
//    -webkit-tap-highlight-color: rgba(0,0,0,0);
//    &:active {
//      margin-top: -54px;
//    }
//    &:hover,
//    &:focus {
//      opacity: 1;
//      @if $mfp-IE7support {
//        filter: unquote("alpha(opacity=#{1*100})");
//      }
//    }
//    &:before,
//    &:after,
//    .mfp-b,
//    .mfp-a {
//      content: '';
//      display: block;
//      width: 0;
//      height: 0;
//      position: absolute;
//      left: 0;
//      top: 0;
//      margin-top: 35px;
//      margin-left: 35px;
//      border: medium inset transparent;
//    }
//
//    &:after,
//    .mfp-a {
//
//      border-top-width: 13px;
//      border-bottom-width: 13px;
//      top:8px;
//    }
//
//    &:before,
//    .mfp-b {
//      border-top-width: 21px;
//      border-bottom-width: 21px;
//      opacity: 0.7;
//    }
//
//  }
//
//  .mfp-arrow-left {
//    left: 0;
//
//    &:after,
//    .mfp-a {
//      border-right: 17px solid $mfp-controls-color;
//      margin-left: 31px;
//    }
//    &:before,
//    .mfp-b {
//      margin-left: 25px;
//      border-right: 27px solid $mfp-controls-border-color;
//    }
//  }
//
//  .mfp-arrow-right {
//    right: 0;
//    &:after,
//    .mfp-a {
//      border-left: 17px solid $mfp-controls-color;
//      margin-left: 39px
//    }
//    &:before,
//    .mfp-b {
//      border-left: 27px solid $mfp-controls-border-color;
//    }
//  }
//}
//
//
//
//// Iframe content type
//@if $mfp-include-iframe-type {
//  .mfp-iframe-holder {
//    padding-top: $mfp-iframe-padding-top;
//    padding-bottom: $mfp-iframe-padding-top;
//    .mfp-content {
//      line-height: 0;
//      width: 100%;
//      max-width: $mfp-iframe-max-width;
//    }
//    .mfp-close {
//      top: -40px;
//    }
//  }
//  .mfp-iframe-scaler {
//    width: 100%;
//    height: 0;
//    overflow: hidden;
//    padding-top: $mfp-iframe-ratio * 100%;
//    iframe {
//      position: absolute;
//      display: block;
//      top: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      box-shadow: $mfp-shadow;
//      background: $mfp-iframe-background;
//    }
//  }
//}
//
//
//
//// Image content type
//@if $mfp-include-image-type {
//
//  /* Main image in popup */
//  img {
//    &.mfp-img {
//      width: auto;
//      max-width: 100%;
//      height: auto;
//      display: block;
//      line-height: 0;
//      -webkit-box-sizing: border-box;
//      -moz-box-sizing: border-box;
//      box-sizing: border-box;
//      padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
//      margin: 0 auto;
//    }
//  }
//
//  /* The shadow behind the image */
//  .mfp-figure {
//    line-height: 0;
//    &:after {
//      content: '';
//      position: absolute;
//      left: 0;
//      top: $mfp-image-padding-top;
//      bottom: $mfp-image-padding-bottom;
//      display: block;
//      right: 0;
//      width: auto;
//      height: auto;
//      z-index: -1;
//      box-shadow: $mfp-shadow;
//      background: $mfp-image-background;
//    }
//    small {
//      color: $mfp-caption-subtitle-color;
//      display: block;
//      font-size: 12px;
//      line-height: 14px;
//    }
//    figure {
//      margin: 0;
//    }
//  }
//  .mfp-bottom-bar {
//    margin-top: -$mfp-image-padding-bottom + 4;
//    position: absolute;
//    top: 100%;
//    left: 0;
//    width: 100%;
//    cursor: auto;
//  }
//  .mfp-title {
//    text-align: left;
//    line-height: 18px;
//    color: $mfp-caption-title-color;
//    word-wrap: break-word;
//    padding-right: 36px; // leave some space for counter at right side
//  }
//
//  .mfp-image-holder {
//    .mfp-content {
//      max-width: 100%;
//    }
//  }
//
//  .mfp-gallery {
//    .mfp-image-holder {
//      .mfp-figure {
//        cursor: pointer;
//      }
//    }
//  }
//
//
//  @if $mfp-include-mobile-layout-for-image {
//    @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
//      /**
//       * Remove all paddings around the image on small screen
//       */
//      .mfp-img-mobile {
//        .mfp-image-holder {
//          padding-left: 0;
//          padding-right: 0;
//        }
//        img {
//          &.mfp-img {
//            padding: 0;
//          }
//        }
//        .mfp-figure {
//          // The shadow behind the image
//          &:after {
//            top: 0;
//            bottom: 0;
//          }
//          small {
//            display: inline;
//            margin-left: 5px;
//          }
//        }
//        .mfp-bottom-bar {
//          background: rgba(0,0,0,0.6);
//          bottom: 0;
//          margin: 0;
//          top: auto;
//          padding: 3px 5px;
//          position: fixed;
//          -webkit-box-sizing: border-box;
//          -moz-box-sizing: border-box;
//          box-sizing: border-box;
//          &:empty {
//            padding: 0;
//          }
//        }
//        .mfp-counter {
//          right: 5px;
//          top: 3px;
//        }
//        .mfp-close {
//          top: 0;
//          right: 0;
//          width: 35px;
//          height: 35px;
//          line-height: 35px;
//          background: rgba(0, 0, 0, 0.6);
//          position: fixed;
//          text-align: center;
//          padding: 0;
//        }
//      }
//    }
//  }
//}
//
//
//
//// Scale navigation arrows and reduce padding from sides
//@media all and (max-width: 900px) {
//  .mfp-arrow {
//    -webkit-transform: scale(0.75);
//    transform: scale(0.75);
//  }
//  .mfp-arrow-left {
//    -webkit-transform-origin: 0;
//    transform-origin: 0;
//  }
//  .mfp-arrow-right {
//    -webkit-transform-origin: 100%;
//    transform-origin: 100%;
//  }
//  .mfp-container {
//    padding-left: $mfp-popup-padding-left-mobile;
//    padding-right: $mfp-popup-padding-left-mobile;
//  }
//}
//
//




