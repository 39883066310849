/*
Display

Change the display property of an element. You can also prefix the classes with breakpoints, such as `palm-display--none`.

Markup:
<div class="display {{modifier_class}}">Display</div>

.display--block - Block
.display--none - None
.display--inline - Inline
.display--inline-block - Inline block

Styleguide trumps.display
*/

.display--block {
	display: block !important;
}

.display--none {
	display: none !important;
}

.display--inline {
	display: inline !important;
}

.display--inline-block {
	display: inline-block !important;
}

@each $breakpoint in setting(breakpoints) {

	$alias:     nth($breakpoint, 1);
	$condition: nth($breakpoint, 2);

	@include media-query($alias) {

		.#{$alias}-display--block {
			display: block !important;
		}

		.#{$alias}-display--none {
			display: none !important;
		}

		.#{$alias}-display--inline {
			display: inline !important;
		}

		.#{$alias}-display--inline-block {
			display: inline-block !important;
		}
	}
}
