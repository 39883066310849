/*
Section

No styleguide reference
*/

.section {
	padding: 0 0 $b 0;
	display: inline-block;
	width: 100%;

	@include media-query(lap-and-up) {
		padding: $b 0 $b*2 0;
	}

	&--grey {
		background: setting(colors, background-light);
	}
}


.sections--zebra {
	.section:nth-child(odd) {
		background-color: setting(colors, background-light);
	}
}
