
$flagsImagePath: "~/images/flags/";
@import "node_modules/intl-tel-input/src/css/intlTelInput.scss";

.intl-tel-input {
  .country-list {
    // spacing between country flag, name and dial code
    .flag, .country-name, .dial-code {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.intl-tel-input .country-list {
  // international-phone-number dropdown
  z-index: z-index(international-phone-number);
}

.intl-tel-input input[type=text] {
  padding-left: 52px !important;
}

// right-to-left adjustments
.rtl {

  .intl-tel-input {

    direction: rtl;

    input, input[type=text], input[type=tel] {
      // cancel left padding and set it as right padding
      padding-left: 0;
      padding-right: $selectedFlagWidth + $inputPadding;

      // for RTL languages, the phone number should be LTR and right-aligned
      direction: ltr !important;
      text-align: right;
    }

    .selected-flag {
      padding-left: 0;

      .iti-flag {
        left: $flagPadding;
      }
    }

    .country-list {
      .country {
        text-align: right;
      }
      .flag, .country-name {
        // cancel right margin and set it as left margin
        margin-left: 6px;
        margin-right: 6px;
        direction: ltr;
      }
      .flag-box {
        margin-right: 0;
      }
      .dial-code {
        // dial codes should be LTR
        direction: ltr;
      }
    }

  }
}
