body.DocCtrl {
    background: setting(colors, background-light);

    .site {
        &__app_header {
            display: none;
        }

        &__main {
            margin-bottom: 0;

            @include media-query(lap-and-up) {
                margin-bottom: $b * 4;
            }
        }

        &__footer {
            display: none;
        }

        &__footer__doc {
            display: block;

            p {
                margin-top: $b;
            }

            .language-switch {
                top: $b;
            }

            .rtl & {
                padding-left: $b * 15 !important;
            }

            @include media-query(lap-and-up) {
                bottom: 0;
                left: 0;
                position: fixed;
                right: 0;
                padding-bottom: $b/2;

                p {
                    margin-top: $b/2;
                }

                .language-switch {
                    top: $b/2;
                }
            }
        }
    }
}

.site {
    &__main {
        margin-bottom: 0;
    }

    &__footer {
        padding: 0 0 $b 0;
        position: relative;
        background: #fff;
        margin-top: 40px;

        body.DocCtrl:not(.vue-sign) & {
            z-index: z-index(footer);
        }

        .sr-container {
            position: relative;
        }

        h4, .delta {
            margin-top: 40px;
            font-size: 16px;
            display: inline;
            @include media-query(lap-and-up) {
                margin-top: 40px;
            }
        }

        .nav {
            font-size: 16px;
            line-height: 1.75;
        }

        p {
            color: #111111;
            margin: 0;
        }

        img.site__footer__banner {
            width: 75px;
            height: 85px;
            object-fit: contain;
            margin-top: 1rem;
        }

        .sr-layout__item__lang {
            position: relative;
            z-index: 20;
        }

        img + img {
            margin: 0 0.5rem;
        }

        a:focus {
            outline-color: rgba(54, 108, 216, 0.7);
            outline-width: 4px;
            outline-style: solid;
            outline-offset: 0.5rem;
        }
        
        &__logo__link {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-top: -7px;
            & > * {
                margin: 0 0.5rem;
            }
            svg {
                width: 150px;
            }
        }

        @include media-query(palm) {
            .nav,
            p {
                margin-top: $b/2;
            }

            .sr-layout__item {
                padding: 0 1.7rem;
            }

            ul.nav {
                margin-left: -$b/8;
                margin-right: -$b/8;

                li {
                    margin: 0px $b/8;
                }
            }
        }

        &:not(.site__footer--marketing) {
          .sr-layout__item:not(.sr-layout__item__always) {
            display: none;
          }
        }
    }
}

body.EmailChangeConfirmCtrl,
body.ReactivateAccountCtrl {
    padding-top: $b * 2;
    padding-bottom: $b * 2;

    .site {
        &__app_header {
            display: none;
        }

        &__footer {
            display: none;
        }
    }
}

body.MainCtrl.vue-homebox,
body.SalesforceController.vue-homebox {
    background-color: #f7f9fe;
}
body.MainCtrl.vue-homebox .site__footer {
  background-color: #f7f9fe;
}

// angular vue migration
i.legacy-icon-padding-fix {
    top: -3px;
    position: relative;
}
