/*
Statusses

Markup:
<p class="status {{modifier_class}}">Status</p>

.status--waiting - Waiting
.status--success - Successful or positive
.status--info - Informational
.status--warning - Caution should be taken
.status--error - errorous or potentially negative

Styleguide components.status
*/

.status {

	white-space: nowrap;

	&:before {
		@extend .icon:before;

		margin-right: 6px;
    .rtl & {
      margin-right: inherit;
      margin-left: 6px;
    }
	}

	&--waiting {
		color: setting(colors, font-light);

		&:before {
			@extend .icon--circle--clock;
		}
	}

  &--time-send {
    color: setting(colors, font-light);
    font-size: inherit;
    margin-top: 3px;
    margin-bottom: 3px;
  }

	&--success {
		color: setting(colors, secundary);

		&:before {
			@extend .icon--circle--tick;
		}
	}

	&--info {
		color: setting(colors, info);

		&:before {
			@extend .icon--circle--question;
		}
	}

	&--warning {
		color: setting(colors, warning);

		&:before {
			@extend .icon--circle--exclamation-mark;
		}
	}

	&--error {
		color: setting(colors, error);

		&:before {
			@extend .icon--circle--cross;
		}
	}
}
