$settings: (
	breakpoints: (
		palm: "screen and (max-width: 767px)",
		lap: "screen and (min-width: 768px) and (max-width: 959px)",
		lap-and-down: "screen and (max-width: 959px)",
		lap-and-up: "screen and (min-width: 768px)",
		desk: "screen and (min-width: 960px)",
		desk-wide: "screen and (min-width: 1200px)"
	),
	colors: (
		primary: #366cd8,
		secundary: #5eb235,
		font: #696e7a,
		font-light: #737373,
		font-dark: #222222,
		border: #ddd,
		border-dark: #aaa,
		background-light: #f8f8f8,
    background-light-gray: #f7f9fe,
    background-light-blue: #e3eafb,
    backgrount-dark-gray: #f1f0f0,
		background-banner: #fff4f4,
		success: #5eb235,
		ok: #5eb235,
		info: #366cd8,
		warning: #e68600,
		error: #d72727,
    // from signer-pages
    brand: #366cd8,
    white: #ffffff,
    lighter: #f8f8f8,
    light: #dddddd,
    dark: #757b88,
    black: #222222,
    green: #5eb235
	),
	brand-colors: (
		twitter: #55acee,
		facebook: #3b5998,
		linkedin: #0976b4,
		google: #dc4e41,
		dropbox: #0062ff,
		onedrive: #094ab2,
		salesforce: #1797C0
	),
	spectrum-colors: (
		purple: #7e3795,
		orange: #e68600,
		green: #61b03d,
		indigo: #3f5ca8,
		red: #d72727,
		blue: #366cd8,
		lime: #c3d140,
		teal: #109d59,
		amber: #f5b400,
		light-blue: #12a9cc,
		brown: #663f30
	),
	border-radius: 3px,
	box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.15),
	font-size: 14,
	rem: 14px,
	font-family: "Lato",
	font-weights: (
		light: 300,
		normal: 400,
		bold: 600
	),
	line-height: 24,
	z-index: spectrum selectize international-phone-number hint hint-arrow pages placeholder__guides header editor__page sig__wrapper img__wrapper placeholder editor__signature editor__tooltip editor__signature__active footer arrow editor__options zoom editor__options__mobile editor__header arrow-on-top modal tooltipster,
  z-index-tooltip: 1030,
  z-index-popup: 1040,
  z-index-overlay: 1050,
  z-index-notification: 1060,
  hover-opacity: .8
);

