/*
Lists

Markup:
<ul>
	<li>Lorem</li>
	<li>Ipsum</li>
	<li>Dolor</li>
	<li>Sit</li>
	<li>Amet</li>
</ul>
<ol>
	<li>Lorem</li>
	<li>Ipsum</li>
	<li>Dolor</li>
	<li>Sit</li>
	<li>Amet</li>
</ol>

Styleguide base.lists
*/

ul, ol {
	list-style-position: inside;
}

li {
	> ul,
	> ol {
		margin-top: 0;
	}
}
