$settings: (
	breakpoints: (
		palm: "screen and (max-width: 767px)",
		lap: "screen and (min-width: 768px) and (max-width: 959px)",
		lap-and-down: "screen and (max-width: 959px)",
		lap-and-up: "screen and (min-width: 768px)",
		desk: "screen and (min-width: 960px)",
		desk-wide: "screen and (min-width: 1200px)"
	),
	colors: (
		primary: #366cd8,
		secundary: #5eb235,
		font: #696e7a,
		font-light: #737373,
		font-dark: #222222,
		border: #ddd,
		border-dark: #aaa,
		background-light: #f8f8f8,
    background-light-gray: #f7f9fe,
    background-light-blue: #e3eafb,
    backgrount-dark-gray: #f1f0f0,
		background-banner: #fff4f4,
		success: #5eb235,
		ok: #5eb235,
		info: #366cd8,
		warning: #e68600,
		error: #d72727,
    // from signer-pages
    brand: #366cd8,
    white: #ffffff,
    lighter: #f8f8f8,
    light: #dddddd,
    dark: #757b88,
    black: #222222,
    green: #5eb235
	),
	brand-colors: (
		twitter: #55acee,
		facebook: #3b5998,
		linkedin: #0976b4,
		google: #dc4e41,
		dropbox: #0062ff,
		onedrive: #094ab2,
		salesforce: #1797C0
	),
	spectrum-colors: (
		purple: #7e3795,
		orange: #e68600,
		green: #61b03d,
		indigo: #3f5ca8,
		red: #d72727,
		blue: #366cd8,
		lime: #c3d140,
		teal: #109d59,
		amber: #f5b400,
		light-blue: #12a9cc,
		brown: #663f30
	),
	border-radius: 3px,
	box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.15),
	font-size: 14,
	rem: 14px,
	font-family: "Lato",
	font-weights: (
		light: 300,
		normal: 400,
		bold: 600
	),
	line-height: 24,
	z-index: spectrum selectize international-phone-number hint hint-arrow pages placeholder__guides header editor__page sig__wrapper img__wrapper placeholder editor__signature editor__tooltip editor__signature__active footer arrow editor__options zoom editor__options__mobile editor__header arrow-on-top modal tooltipster,
  z-index-tooltip: 1030,
  z-index-popup: 1040,
  z-index-overlay: 1050,
  z-index-notification: 1060,
  hover-opacity: .8
);

@function map-get-deep($map, $keys...) {
	$value: $map;
	@each $key in $keys {
		$value: map-get($value, $key);
	}
	@return $value;
}


@function diapositive($color) {
	@if (lightness($color) > 50) {
		@return #000;
	} @else {
		@return #fff;
	}
}

@function setting($keys...) {
	$value: $settings;
	@each $key in $keys {
		$value: map-get($value, $key);
	}
	@return $value;
}

@function z-index($key) {
	@return index(map-get($settings, z-index), $key);
}
@mixin font-size($font-size, $line-height: auto) {
	font-size: $font-size * 1px;
	font-size: ($font-size / setting(font-size)) * 1rem;

	@if $line-height == auto {
		line-height: ceil($font-size / setting(line-height)) * (setting(line-height) / $font-size);
	}

	@else {

		@if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
			line-height: $line-height;
		}

		@elseif ($line-height != none and $line-height != false) {
			@warn "'#{$line-height}' is not a valid value for `line-height`."
		}

	}

}

@mixin grid-setup($namespace: "") {
	/**
	* Whole
	*/
	.#{$namespace}one-whole         { width:100%; }


	/**
	* Halves
	*/
	.#{$namespace}one-half          { width:50%; }


	/**
	* Thirds
	*/
	.#{$namespace}one-third         { width:33.333%; }
	.#{$namespace}two-thirds        { width:66.666%; }


	/**
	* Quarters
	*/
	.#{$namespace}one-quarter       { width:25%; }
	.#{$namespace}two-quarters      { @extend .#{$namespace}one-half; }
	.#{$namespace}three-quarters    { width:75%; }


	/**
	* Fifths
	*/
	.#{$namespace}one-fifth         { width:20%; }
	.#{$namespace}two-fifths        { width:40%; }
	.#{$namespace}three-fifths      { width:60%; }
	.#{$namespace}four-fifths       { width:80%; }


	/**
	* Sixths
	*/
	.#{$namespace}one-sixth         { width:16.666%; }
	.#{$namespace}two-sixths        { @extend .#{$namespace}one-third; }
	.#{$namespace}three-sixths      { @extend .#{$namespace}one-half; }
	.#{$namespace}four-sixths       { @extend .#{$namespace}two-thirds; }
	.#{$namespace}five-sixths       { width:83.333%; }

	/**
	* Sevenths
	*/
	.#{$namespace}one-seventh       { width: percentage(1/7); }
	.#{$namespace}two-sevenths      { width: percentage(2/7); }
	.#{$namespace}three-sevenths    { width: percentage(3/7); }
	.#{$namespace}four-sevenths     { width: percentage(4/7); }
	.#{$namespace}five-sevenths     { width: percentage(5/7); }
	.#{$namespace}six-sevenths      { width: percentage(6/7); }


	/**
	* Eighths
	*/
	.#{$namespace}one-eighth        { width:12.5%; }
	.#{$namespace}two-eighths       { @extend .#{$namespace}one-quarter; }
	.#{$namespace}three-eighths     { width:37.5%; }
	.#{$namespace}four-eighths      { @extend .#{$namespace}one-half; }
	.#{$namespace}five-eighths      { width:62.5%; }
	.#{$namespace}six-eighths       { @extend .#{$namespace}three-quarters; }
	.#{$namespace}seven-eighths     { width:87.5%; }


	/**
	* Tenths
	*/
	.#{$namespace}one-tenth         { width:10%; }
	.#{$namespace}two-tenths        { @extend .#{$namespace}one-fifth; }
	.#{$namespace}three-tenths      { width:30%; }
	.#{$namespace}four-tenths       { @extend .#{$namespace}two-fifths; }
	.#{$namespace}five-tenths       { @extend .#{$namespace}one-half; }
	.#{$namespace}six-tenths        { @extend .#{$namespace}three-fifths; }
	.#{$namespace}seven-tenths      { width:70%; }
	.#{$namespace}eight-tenths      { @extend .#{$namespace}four-fifths; }
	.#{$namespace}nine-tenths       { width:90%; }


	/**
	* Twelfths
	*/
	.#{$namespace}one-twelfth       { width:8.333%; }
	.#{$namespace}two-twelfths      { @extend .#{$namespace}one-sixth; }
	.#{$namespace}three-twelfths    { @extend .#{$namespace}one-quarter; }
	.#{$namespace}four-twelfths     { @extend .#{$namespace}one-third; }
	.#{$namespace}five-twelfths     { width:41.666% }
	.#{$namespace}six-twelfths      { @extend .#{$namespace}one-half; }
	.#{$namespace}seven-twelfths    { width:58.333%; }
	.#{$namespace}eight-twelfths    { @extend .#{$namespace}two-thirds; }
	.#{$namespace}nine-twelfths     { @extend .#{$namespace}three-quarters; }
	.#{$namespace}ten-twelfths      { @extend .#{$namespace}five-sixths; }
	.#{$namespace}eleven-twelfths   { width:91.666%; }
}
$b: (setting(line-height)/setting(font-size))*1rem;










































































.sr-signature-button {
  display: block;
  position: relative;
  width: 100%;
}

.img-btn {
  display: block;
  border-radius: 5px;

  color: setting(colors, border-dark);
  border: 0.5px solid currentColor;
  transition: all 0.2s;
  font-size: 0px;

  width: 100%;
  min-height: 100%;
}

.img-btn:hover:not(:disabled),
.img-btn.selected:not(:disabled),
.img-btn:focus:not(:disabled) {
  color: inherit;
}

.img-btn:focus:not(:disabled) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.img-btn:disabled {
  filter: grayscale(100%);
  cursor: default;
}

.signrequest-lib .img-btn {
  border-color: #d3d3d3;
  border-style: inset;
}
.signrequest-lib .img-btn.selected {
  outline-color: #0061d5;
  outline-width: 3px;
  outline-style: solid;
}

.fake-checkbox {
  position: absolute;
  width: 12px;
  height: 12px;

  bottom: 4px;
  right: 5px;

  border: 0.5px solid currentColor;
  border-radius: 50%;
  box-sizing: content-box;
  display: block;
}

.rtl .fake-checkbox {
  right: unset;
  left: 5px;
}

.fake-checkbox::after {
  content: '';
  background-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  display: block;
  position: absolute;
  opacity: 0;
}

.img-btn:hover .fake-checkbox::after,
.img-btn:focus .fake-checkbox::after,
.img-btn.selected .fake-checkbox::after {
  opacity: 1;
}

.signrequest-lib .fake-checkbox {
  display: none;
}

.img-btn ::v-deep canvas {
  width: 100%;
  height: auto;
}

.img-btn ::v-deep img {
  width: 100%;
  height: auto;
}

.img-btn ::v-deep canvas,
.img-btn ::v-deep img,
.img-btn .fake-checkbox {
  pointer-events: none;
}

.delete-btn {
  position: absolute;
  color: setting(colors, border-dark);

  width: 18px;
  height: 20px;

  bottom: 2px; /* i hate this */
  left: 3px; /* and i hate this one pixel especially */

  display: flex;
  opacity: 0.75;

  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.rtl .delete-btn {
  right: unset;
  right: 2px; /* yep, still hate it */
}

.delete-btn .trash-icon {
  display: inline-flex;
  width: 100%;
}

.delete-btn:hover,
.delete-btn:focus {
  color: setting(colors, error);
  transform: scale(1.2);
}
