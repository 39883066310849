/*
Magnific popup

No styleguide reference.
*/

////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color:                   #000000;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8;                        // Opacity of overlay screen
$mfp-shadow:                          none; 					  // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              0;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       0;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    setting(z-index-popup);                        // Base z-index of popup

// controls
$mfp-include-arrows:                  true;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65;                       // Opacity of controls
$mfp-controls-color:                  #FFF;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F; 	                  // Border color of controls
$mfp-inner-close-icon-color:          #333;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF;                       // Hover color of preloader and "1 of X" indicator
$mfp-IE7support:                      true;                       // Very basic IE7 support

// Iframe-type options
$mfp-include-iframe-type:             true;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              $b/2;                       // Iframe padding top
$mfp-iframe-background:               #000;                       // Background color of iframes
$mfp-iframe-max-width:                900px;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px;                       // Image padding top
$mfp-image-padding-bottom:            40px;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false;

@import "node_modules/magnific-popup/src/css/main";

.mfp {

	&-container {
		padding-left: $b;
		padding-right: $b;
	}

}

button.mfp-close {
	width: $b*1.25;
	height: $b*1.25;
	line-height: $b*1.25;
	top: $b*-0.625 !important;
	right: $b*-0.625;
	background: #222222;
	color: #fff !important;
	opacity: 1;
	border-radius: 50%;
	font-family: inherit;
	font-weight: setting(font-weights, normal);
	font-size: $b;
	.rtl & {
     	right: inherit;
     	left: $b*-0.625;
    }

  &.inline {
    position: relative;
    top: inherit!important;
    right: inherit;
  }

}

/* overlay at start */
.mfp-fade {

  &.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;

    /* overlay animate in */
    &.mfp-ready{
      opacity: 0.8;
    }

    /* overlay animate out */
    &.mfp-removing{
      opacity: 0;
    }

  }


  /* content at start */
  &.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -webkit-overflow-scrolling: touch;

  }

  /* content animate it */
  &.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
  }

  /* content animate out */
  &.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
  }

}

