.complete {
  h1 {
    font-size: 2.2rem;
    color: setting(colors, primary);
  }

  .quick-register {
    text-align: center;
  }

  #register-pass {
    display: inline-block;
  }
}
