/*
Media

Place any image and text-like content side-by-side.

Markup:
<div class="media {{modifier_class}}">
	<div class="media__object">
		<img src="http://placehold.it/96x96">
	</div>
	<div class="media__body">
		<p>Lorem ipsum dolor sit amet</p>
	</div>
</div>

.media--rev - Put the object at the other side
.media--narrow - Lesser padding
.media--flush - Remove margins

Styleguide objects.media
*/

.media {
	@extend .clearfix;
	display: block;

	&__img,
	&__object {
		float: left;
		margin-right: $b;
    .rtl & {
      float: right;
      margin-left: $b;
      margin-right: inherit;
    }

		> img {
			display: block;
		}
	}

	&__body {
		overflow: hidden;
		display: inline-block;

		&,
		> :first-child {
			margin-top: 0;
		}
	}

	&--rev {
		> .media__img,
		> .media__object {
			float: right;
			margin-right: 0;
			margin-left: 0;
      .rtl & {
        float: left;
      }
		}
	}

	&--flush {
		> .media__img,
		> .media__object {
			margin-right: 0;
			margin-left: 0;
		}
	}

	&--narrow {
		> .media {
			&__img,
			&__object {
				margin-right: $b/2;
        .rtl & {
          margin-left: $b/2;
          margin-right: inherit;
        }
			}
		}

		&.media--rev {
			.media {
				&__img,
				&__object {
					margin-left: $b/2;
					margin-right: 0;
          .rtl & {
            margin-left: 0;
            margin-right: $b/2;
          }
				}
			}
		}
	}
}
