.subscription {

  .subscription-quantity {
    display: none;

    .input-group {
      background: setting(colors, white);
    }

  }
  &.selected {
    border-color: setting(colors, primary);

    h2 {
      color: setting(colors, primary);
    }

    .subscription-quantity {
      display: inherit;
    }

  }

  .prices{
    //font-size: large;
    font-weight: bold !important;
  }

}

.fadeIn {
  opacity: 0; /* make things invisible upon start */
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  animation: fadeIn ease-in 1;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  animation-fill-mode: forwards;
  animation-duration: .5s;
}
