/*
Indicator

Markup:
<a href="#" class="indicator"></a>

Styleguide components.indicator
*/

.indicator {
	display: block;
	width: $b/2;
	height: $b/2;
	border-radius: 50%;
	border: 1px solid setting(colors, primary);
	background-color: rgba(setting(colors, primary), 0.1);
	position: absolute;
	right: 0;
	top: 100%;
	margin-top: $b*-0.25;

	.styleguide__example & {
		position: relative;
		top: auto;
		right: auto;
		margin: 0;
	}
}
