/*
Notifications

Notification and loading indicator in one element. When loading is finished, add the done class.

Markup:
<div class="notification {{modifier_class}}">
	<div class="notification__body">
		<p>We've send you a mail with further instructions.</p>
	</div>
	<div class="notification__close">
		<i class="icon icon--cross"></i>
	</div>
</div>

.notification--loading    - Loading
.notification--done 	  - Done
.notification--success    - Success
.notification--warning    - Warning
.notification--error     - error

Styleguide components.notification
*/

.notification {
	position: fixed;
	top: $b;
	left: $b;
	z-index: setting(z-index-notification); // over overlay and magnific popup
	background: setting(colors, primary);
	color: #fff;
	border-radius: setting(border-radius);
	width: 320px;
	min-height: $b*3;
	display: inline-block;
	overflow: hidden;

	a {
		color: inherit;
	}

	.styleguide__example & {
		position: relative;
		top: auto;
		left: auto;
	}

	&__body {
		backface-visibility: hidden;
		padding: 0 $b/2 $b/2 $b/2;

		> :first-child {
			margin-top: $b/2;
		}
	}

	&--loading {
		//animation: notification__fade 0.9s ease-in-out, notification__spin 1.6s infinite ease-in-out;
		//animation: flip 1s infinite;

		.notification__body,
		.notification__close {
			//opacity: 0 !important;
		}
	}

	&--done {
		animation: notification__expand .3s cubic-bezier(0.7,0,0.3,1);

		.notification__body,
		.notification__close {
			animation-name: notification__fade;
			animation-delay: .3s;
			animation-fill-mode: both;
		}
	}

	&__close {
		position: absolute;
		right: 0;
		top: 0;
		padding: $b/2;
		color: #fff;
		opacity: .6;
		cursor: pointer;
		transition: opacity .2s;

		&:hover {
			opacity: 1;
			text-decoration: none;
		}

		.icon:before {
			display: block;
			width: 16px;
			height: 16px;
		}
	}

	&--success {
		background-color: setting(colors, secundary);
	}

	&--info {
		background-color: setting(colors, info);
	}

	&--warning {
		background-color: setting(colors, warning);
	}

	&--error {
		background-color: setting(colors, error);
	}

}


@keyframes notification__fade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}


@keyframes notification__spin {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		width: $b*3;
		height: $b*3;
	} 50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	} 100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		width: $b*3;
		height: $b*3;
	}
}

@keyframes notification__expand {
	0% { width: $b*3; height: $b*3; }
	100% { width: 320px; height: $b*3; }
}
