
.editor {

  padding: $b*2 0 0 0;


  &__header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    z-index: z-index(editor__header);
    overflow: hidden;
    box-shadow: setting(box-shadow);
    height: $b*2;

    .button {
      margin: 0;
    }

  }

  &__title {
    text-align: center;
    padding: 0 0;

    * {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__heading {
    font-weight: setting(font-weights, bold);
    margin: 0 5px;
    @include font-size(14, setting(line-height)*0.75/14);

  }

  &__status {
    //margin-top: $b/1.5;
    @include font-size(12, setting(line-height)*0.75/12);

    &:before {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &__options {
    position: fixed;
    top: $b*2;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: setting(box-shadow);
    padding: 0 $b $b $b;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: z-index(editor__options);
    .rtl & {
      right: inherit;
      left: 0;
    }

    .signer-options {

      .email, .tools {
        padding: 12px 24px 11px $b/2;
        .rtl & {
          padding: 12px $b/2 11px 24px;
        }
      }

      .tools {
        float: right;
        width: 110px;
      }

      .email {
        margin-right: 110px;
        word-break: break-all;
        border: 1px solid currentColor;
        position: relative;

        .input--text {
          padding: 7px 12px;
        }

        &.signer--inline-edit {
          padding-top: 0;
        }

        .email--background::before {
          display: block;
          content: '';
          background: currentColor;
          opacity: 0.1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          z-index: -1;
        }
      }

      .tools--order {
        position: absolute;
        top: 0;
        right: 0;
        width: 46px;
        padding: 12px 24px 11px 0;
        text-align: center;
        border-radius: map-get($forms, border-radius);
        border: 1px solid map-get($forms, border-color);
      }

      .tools--change--level {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 58px;
        border-radius: map-get($forms, border-radius);
        position: absolute;
        top: 6px;
        right: 0;

        .icon {
          margin: 0;
        }

        .fa {
          width: 16px;
        }

      }

      .signer--inline-edit .tools--extra-options {
        top: 16px;
      }

      .tools--extra-options {
        display: inline-block;
        position: absolute;
        right: 7px;
        top: 11px;

        .rtl & {
          right: inherit;
          left: 7px;
        }
      }

      li {
        position: relative;
        margin-top: $b/2;
        margin-top: $b/2;
        list-style: none;

        .attachment-list{
          list-style-type: circle;
          text-align:center;
        }
      }

      .tools--order--change {
        position: absolute;
        top: 0;
        right: 0;

        .editor__zoom__item {
          border-color: map-get($forms, border-color);
        }

      }

    }

    form.add_signer {
      input.ng-dirty {
        border-color: inherit;
      }
    }
  }

  &__results {
    overflow-y: scroll;
    height: 200px;
    padding: 12px 24px 11px $b/2;

    .rtl & {
      padding: 12px $b/2 11px 24px;
    }

    ul {
      margin-top: 1px;
      list-style-type: none;
    }

    li {
      border-bottom: 1px solid map-get($forms, border-color);
      margin-top: 4px;
      line-height: 2;
      cursor: pointer;

      &:hover, &.active {
        color: setting(colors, primary);
        border-color: setting(colors, primary);
      }
    }
  }

  &__attachments {
    position: fixed;
    top: $b*2.5;
    right: $b/2;
    z-index: z-index(zoom);

    .rtl & {
      right: inherit;
      left: $b/2;
    }

    .fa-paperclip {
      font-size: 1.8em;
      margin-top: 4px;
    }

    .button {
      margin-top: 0;
    }

  }

  &__zoom {
    position: fixed;
    top: $b*2.5;
    left: $b/2;
    box-shadow: setting(box-shadow);
    z-index: z-index(zoom);

    .rtl & {
      left: inherit;
      right: $b/2;;
    }

    &__item {
      position: relative;
      display: block;
      width: 25px;
      height: 25px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid setting(colors, primary);

      &:before,
      &:after {
        position: absolute;
        display: block;
        content: "";
        background: setting(colors, primary);
        transition: background .2s;
      }

      &:hover {
        background: setting(colors, primary);

        &:before,
        &:after {
          background-color: #fff;
        }
      }


      &:before {
        width: 13px;
        height: 1px;
        top: 11px;
        left: 5px;
      }

      &--in {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:after {
          width: 1px;
          height: 13px;
          top: 5px;
          left: 11px;
        }
      }

      &--out {
        height: 24px;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &__pages {
    text-align: center;
    overflow-y: hidden;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 6rem;
    margin-bottom: -6rem;
  }

  &__docinfo {

    text-align: center;

    a {
      cursor: pointer;
    }

    margin-bottom: -3rem;
  }

  &__page {
    margin: $b $b/4 $b/4 $b/4;

    position: relative;
    display: inline-block;
    user-select: none;
    z-index: z-index(editor__page);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px;

    img {
      display: block;
      width: 100%;
      user-select: none;
      background: #fff;

      &.page-img.loaded {
        /* do not use a border here (messes up image width and height calculation) */

      }
    }

    .editor--wrapper {
      user-select: none;

      .sig--wrapper {
      z-index: z-index(sig__wrapper);

        .tool--wrapper {

          width: 100%;
          height: 100%;
          position: relative;
          z-index: z-index(editor__signature);

          .img--wrapper {
            z-index: z-index(sig__wrapper);
            width: 100%;
            height: 100%;

            &.editable{
              cursor: pointer;

              &:hover img {
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
              }

              &.active {
                img {
                  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
                }
                &.draggable {
                  cursor: move;
                }
                z-index: z-index(editor__signature__active);
              }

            }

            img {
              background: none;
            }

          }


          .resize-handle {
            position: absolute;

            right: -41px;
            bottom: -37px;
            font-size: 2em;
            color: black;
            width: 52px;
            cursor: nwse-resize;
            z-index: z-index(zoom);
          }

        }
      }

    }

    .tooltip--signature {
      z-index: z-index(editor__tooltip);
    }

  }

  &__indicators {
    position: fixed;
    right: $b*0.5;
    top: $b*2.75;
    bottom: $b*0.75;
    .rtl & {
      right: inherit;
      left: $b*0.5;
    }
  }

  &__options {
    z-index: z-index(editor__options);
    visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(100%);
    transition: transform 0.3s, visibility 0s 0.3s;

    .rtl & {
      transform: translateX(-100%);
    }

    &--active {
      visibility: visible;
      transform: translateX(0%);
      transition: transform 0.3s;
      .rtl & {
        transform: translateX(0%);
      }
    }

    a.help-center-link {
        font-size: 16px;
        font-weight: bold;
    }

  }

  @include media-query(palm) {
    &__logo, &__header__customlogo {
      display: none;
    }

    &__title {
      padding-left: $b*4;
      padding-right: $b*4;
    }

    &__heading {
      @include font-size(12, setting(line-height)*0.75/12);
    }

    &__option,
    &__submit {
      position: absolute;
      top: $b/3;
    }

    &__option {
      float: left;
      left: $b/2;
      .rtl & {
        float: right;
        right: $b/2;
        left: inherit;
      }
    }

    &__submit {
      float: right;
      right: $b/2;
      .rtl & {
        float: left;
        left: $b/2;
        right: inherit;
      }
    }

    &__docinfo {
      padding-top: 0;
    }

    &__options {
      z-index: z-index(editor__options__mobile);
      visibility: hidden;
      backface-visibility: hidden;
      transform: translateY(100%);
      transition: transform 0.3s, visibility 0s 0.3s;

      &--active {
        visibility: visible;
        transform: translateY(0%);
        transition: transform 0.3s;
      }
    }

    &__actions span#finalizeHelp {
      // else the title is not vertically centered anymore on mobile
      display: block;
    }

  }

  .mfp-close {
    top: $b/2!important;
    right: $b/2;
    .rtl & {
      right: inherit;
      left: $b/2;;
    }
  }

  @include media-query(lap-and-up) {

    .mfp-close {
      display: none;
    }

    transition: padding 0.3s;

    &.side-bar-open {
      padding-right: $b*17;
      .rtl & {
        padding-right: inherit;
        padding-left: $b*17;
      }
    }

    &__logo {
      display: table;
      height: 100%;
      position: absolute;
      top: 0;

      &__inner {
        display: table-cell;
        vertical-align: middle;

        // customer logo's are img, our logo is svg
        svg, img {
          display: block;
          max-height: 27px;
          max-width: 200px;
        }

      }

    }

    &__title {
      padding-left: $b*8;
      padding-right: $b*8;
    }

    &__actions {
      position: absolute;
      right: $b;
      top: .67rem;

      .rtl & {
        right: inherit;
        left: $b;
      }

      .button {
        margin-left: $b/2 !important;
        .rtl & {
          margin-left: 0 !important;
          margin-right: $b/2 !important;
        }
      }

    }

    &__options {
      width: $b*17;

      &-trigger {
        display: none;
      }

      #attachment-list{
        list-style-type: circle;
      }
    }

    &__indicators {
      right: $b*17.5;
      .rtl & {
        right: inherit;
        left: $b*17.5;
      }
    }
  }
}
