/*
Timer

Markup:


Styleguide components.timer
*/

.sr-timer {
  position: relative;
  display: inline-block;

  &__seconds {
    display: inline-block;
    margin-top: 12px;
    margin-left: -50%;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  countdown {
    //position: relative;
    svg {
      position: relative;
      top: 0px;
      stroke: setting(colors, primary);
      fill: setting(colors, primary);
    }
  }
}
