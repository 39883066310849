/*
Styleguide

Layout styling for the styleguide

No styleguide reference.
*/

.styleguide {

  .block__box {
    background-color: black;
  }

	&__side {
		position: fixed;
		width: 200px;
		top: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: $b;

		a {
			color: setting(colors, font);
		}
	}

	&__body {
		margin-left: 200px;
		padding: 0 $b $b $b;
		background: setting(colors, background-light);
	}

	&__nav {
		&,
		ul {
			list-style: none;
		}

		a {
			padding: $b/4 $b;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		> li {
			margin-top: $b;

			> a {
				text-transform: uppercase;
				font-size: .75em;
				font-weight: #f8f8f8;
			}
			> ul {
				margin-top: 0;
			}
		}
	}

	&__section {
		padding-top: $b;
	}

	&__block {
		background: #fff;
		border-radius: 3px;
		border: 1px solid setting(colors, border);

		&__item {
			padding: $b;
			display: inline-block;
			width: 100%;

			> :first-child {
				margin-top: 0;
			}

			+ .styleguide__block__item {
				border-top: 1px solid setting(colors, border);
			}
		}
	}

	&__parameter {
		&__name {
			padding-right: $b;
		}
		&__descripion {

		}
	}

	&__description {

	}

	&__example {
		@extend .clearfix;

		position: relative;
		z-index: 1;

		&:hover {
			.styleguide {
				&__modifier {
					background: setting(colors, background-light);
				}
			}
		}
	}

	&__code {

	}

	&__modifier {
		position: absolute;
		right: 0;
		top: 0;
		padding: 2px 6px;
		border-width: 0 0 1px 1px;
		border-style: solid;
		border-color: setting(colors, border-color);
		color: setting(colors, font);
		background: #fff;
	}

	&__markup {
		border-top: 1px solid setting(colors, border);

		.hljs {
			background: #fff;
			padding: $b;
			font-size: 13px;
			line-height: 18px;
		}
	}

	&__swatch {
		position: relative;
		width: $b*5;
		height: $b*5;
		display: block;
		padding: $b/2;
		margin-top: $b;
		border-radius: 3px;

		&__footer {
			position: absolute;
			bottom: $b/2;
		}

		@each $key, $value in setting(colors) {
			&--#{"" + $key} {
				background-color: $value;
				color: diapositive($value);
				border: 1px solid darken($value, 10);
			}
		}


		@each $key, $value in setting(spectrum-colors) {
			&--#{"" + $key} {
				background-color: $value;
				color: diapositive($value);
				border: 1px solid darken($value, 10);
			}
		}	}

	&__colors {
		&__item {
			width: 120px;
			height: 120px;
			display: block;
		}
	}
}

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
	display: block;
	overflow-x: auto;
	color: #333;
	-webkit-text-size-adjust: none;

	&-comment,
	.diff &-header,
	&-javadoc {
		color: #998;
		font-style: italic;
	}

	&-keyword,
	.css .rule &-keyword,
	&-winutils,
	.nginx &-title,
	&-subst,
	&-request,
	&-status {
		color: #333;
		font-weight: bold;
	}

	&-keyword,
	.css .rule &-keyword,
	&-winutils,
	.nginx &-title,
	&-subst,
	&-request,
	&-status {
		color: #333;
		font-weight: bold;
	}

	&-number,
	&-hexcolor,
	.ruby &-constant {
		color: #008080;
	}

	&-string,
	&-tag &-value,
	&-phpdoc,
	&-dartdoc,
	.tex &-formula {
		color: #d14;
	}

	&-title,
	&-id,
	.scss &-preprocessor {
		color: #900;
		font-weight: bold;
	}

	&-list &-keyword,
	&-subst {
		font-weight: normal;
	}

	&-class &-title,
	&-type,
	.vhdl &-literal,
	.tex &-command {
		color: #458;
		font-weight: bold;
	}

	&-tag {
		color: #000080;
		font-weight: normal;

		.hljs-title {
			color: #000080;
			font-weight: normal;
		}
	}

	&-rule &-property,
	.django &-tag &-keyword {
		color: #000080;
		font-weight: normal;
	}

	&-attribute,
	&-variable,
	.lisp &-body, &-name {
		color: #008080;
	}

	&-regexp {
		color: #009926;
	}

	&-symbol,
	.ruby &-symbol &-string,
	.lisp &-keyword,
	.clojure &-keyword,
	.scheme &-keyword,
	.tex &-special,
	&-prompt {
		color: #990073;
	}

	&-built_in {
		color: #0086b3;
	}

	&-preprocessor,
	&-pragma,
	&-pi,
	&-doctype,
	&-shebang,
	&-cdata {
		color: #999;
		font-weight: bold;
	}

	&-deletion {
		background: #fdd;
	}

	&-addition {
		background: #dfd;
	}

	.diff &-change {
		background: #0086b3;
	}

	&-chunk {
		color: #aaa;
	}


}
