/*
Avatar

No styleguide reference
*/

.avatar {
	&,
	img {
		border-radius: 50%;
	}
}
