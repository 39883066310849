
.video-container {
  position: relative;
  padding-bottom: 56.25%;  // video's aspect ratio of 16*9, so 9 divided by 16 = 0.5625 or 56.25%
  padding-top: 30px;
  margin-top: $b;
  height: 0;
  overflow: hidden;

  iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}
