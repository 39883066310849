/*
List groups

List groups are a flexible and powerful component for displaying not only simple lists of elements, but complex ones with custom content.

Markup:
<ul class="list-group">
	<li class="list-group__item">Cras justo odio</li>
	<li class="list-group__item">Dapibus ac facilisis in</li>
	<li class="list-group__item">Morbi leo risus</li>
	<li class="list-group__item">Porta ac consectetur ac</li>
	<li class="list-group__item">Vestibulum at eros</li>
</ul>
<div class="list-group">
	<a href="#" class="list-group__item">Cras justo odio</a>
	<a href="#" class="list-group__item">
		<div class="list-group__object">
			<i class="icon icon--document"></i>
		</div>
		<div class="list-group__body">
			Dapibus ac facilisis in
		</div>
	</a>
	<a href="#" class="list-group__item">Morbi leo risus</a>
	<a href="#" class="list-group__item">Porta ac consectetur ac</a>
	<a href="#" class="list-group__item">Vestibulum at eros</a>
</div>

.list-group--flush - Remove padding

Styleguide components.list-group
*/

.list-group {
    // background: #fff;
    margin-top: 0;
    margin-bottom: -1px;

    > a {
        color: setting(colors, font);
        // padding-right: $b*1.5 !important;

        // &:after {
        // 	@extend .icon:before;
        // 	@extend .icon--arrow-right:before;

        // 	position: absolute;
        // 	top: 50%;
        // 	right: $line-height/2;
        // 	margin-top: -8px;
        // 	color: setting(colors, font-light);
        // 	transition: transform .2s, color .2s;
        // }

        &.active,
        &:hover,
        &:focus {
            text-decoration: none;
            color: setting(colors, primary);

            // &:after {
            // 	transform: translateX(6px);
            // 	color: setting(colors, primary);
            // }
        }
    }

    img {
        margin: 0;
    }

    &__item {
        @extend .clearfix;

        position: relative;
        color: setting(colors, font);
        border-bottom: 1px solid setting(colors, border);
        padding: $b/2 $b/2;
        display: table;
        width: 100%;

        // .icon {
        // 	color: setting(colors, font-light);
        // }

        &.flag,
        > .flag {
            margin-top: 0;
        }

        .button {
            margin: 0;
        }

        .float--right {
            margin-bottom: 0;
        }
    }

    &__object,
    &__body {
        display: table-cell;
        vertical-align: middle;
    }

    &__object {
        width: $b;
        text-align: center;

        img {
            vertical-align: inherit;
        }
    }

    &__body {
        padding-left: $b/2;
        .rtl & {
            padding-left: initial;
            padding-right: $b/2;
        }
        .ltr & {
            padding-left: $b/2;
            padding-right: initial;
        }
    }

    &--flush {
        .list-group__item {
            padding: 0;
        }
    }
    &--nav {
        .list-group__item {
            padding: $b/2 $b/2;
            cursor: pointer;

            &.active {
                color: setting(colors, primary);
            }
        }
    }

    // a .icon {
    // 	&:hover {
    // 		color: setting(colors, primary);
    // 	}
    // }

    &--lang {
      .list-group__body {
        white-space: nowrap;
      }
    }
}
