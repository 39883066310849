$transform: (
	dot-size: $b/2
);

/*
Transform

Resize an element

Markup:
<div class="transform">
	<img src="http://placehold.it/200x72">
	<div class="transform__grip transform__grip--top-left"></div>
	<div class="transform__grip transform__grip--top-right"></div>
	<div class="transform__grip transform__grip--bottom-left"></div>
	<div class="transform__grip transform__grip--bottom-right"></div>
</div>
<div class="transform transform--text">
	<p>SignRequest makes electronic document signing as painless as possible</p>
	<div class="transform__grip transform__grip--top-left"></div>
	<div class="transform__grip transform__grip--top-right"></div>
	<div class="transform__grip transform__grip--bottom-left"></div>
	<div class="transform__grip transform__grip--bottom-right"></div>
</div>

Styleguide components.transform
*/

.transform {
	display: inline-block;
	position: relative;
	background: rgba(setting(colors, primary), .1);
	margin: $b;

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: 0;
		max-width: none;
	}

	&__grip {
		position: absolute;
		width: map-get($transform, dot-size);
		height: map-get($transform, dot-size);
		background: setting(colors, primary);
		border-radius: 50%;
		transition: background .2s;

		&--top-left {
			top: map-get($transform, dot-size)/2*-1;
			left: map-get($transform, dot-size)/2*-1;
			cursor: nwse-resize;
		}

		&--top-right {
			top: map-get($transform, dot-size)/2*-1;
			right: map-get($transform, dot-size)/2*-1;
			cursor: nesw-resize;
		}

		&--bottom-left {
			bottom: map-get($transform, dot-size)/2*-1;
			left: map-get($transform, dot-size)/2*-1;
			cursor: nesw-resize;
		}

		&--bottom-right {
			bottom: map-get($transform, dot-size)/2*-1;
			right: map-get($transform, dot-size)/2*-1;
			cursor: nwse-resize;
		}

		&:hover {
			background: rgba(setting(colors, primary), .5);
		}
	}

	&--text {
		padding: 0 $b/2 $b/2 $b/2;

		:first-child {
			margin-top: $b/2;
		}
	}

}
