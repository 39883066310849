.signature {

  &__list {

    &.radio--image {
      &.disabled {

        &, & img {
          opacity: 0.7;
          cursor: not-allowed;
        }
        .signature__delete {
          display: none;
        }

      }
      span {
        top: $b/4;
        left: $b/4;
        .rtl & {
          left: inherit;
          right: $b/4;
        }
      }
    }

    img {
      max-height: 500px;
    }

  }

  &__delete {
    position: absolute;
    right: -11px;
    top: -11px;
    color: setting(colors, border-dark);

    .rtl & {
      right: inherit;
      left: -11px;
    }

    &:before {
      background-color: #fff;
      height: 23px;
    }

    &:hover {
      color: setting(colors, error)!important;
    }

  }

}
