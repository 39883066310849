$tooltip: (
	color: #fff,
	background-color: #222222,
	background-color-hover: setting(colors, primary),
	border: #444444,
	arrow-size: $b/4
);

/*
Tooltips

Tooltips are like hints, but initialized with JavaScript and with more inner content options.

Markup:
<div class="tooltip {$modifiers}">Hello you</div>

.tooltip--left - Left
.tooltip--right - Right
.tooltip--bottom - Bottom
.tooltip--success - Success
.tooltip--info - Info
.tooltip--warning - Warning
.tooltip--error - Error

Styleguide 40
*/

.tooltip {
	@extend .clearfix;

	position: relative;
	display: inline-block;
	background-color: map-get($tooltip, background-color);
	border-color: map-get($tooltip, background-color);
	border-radius: setting(border-radius);
	z-index: z-index(tooltip);
	padding: $b/4 $b/2;

	&,
	a {
		color: map-get($tooltip, color);
	}

	&:before {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;
		border-top-color: inherit;
		border-width: map-get($tooltip, arrow-size);
		margin-left: map-get($tooltip, arrow-size)*-1;
	}

	&--left {
		&:before {
			top: 50%;
			left: 100%;
			border-left-color: inherit;
			border-top-color: transparent;
			margin-left: 0;
			margin-top: map-get($tooltip, arrow-size)*-1;
		}
	}

	&--right {
		&:before {
			top: 50%;
			left: 0%;
			border-right-color: inherit;
			border-top-color: transparent;
			margin-left: map-get($tooltip, arrow-size)*-2;
			margin-top: map-get($tooltip, arrow-size)*-1;
		}
	}

	&--bottom {
		&:before {
			top: 0;
			border-bottom-color: inherit;
			border-top-color: transparent;
			margin-top: map-get($tooltip, arrow-size)*-2;
		}
	}

	&--success {
		background-color: setting(colors, secundary);

		&:before {
			border-color: rgba(setting(colors, secundary), 0);
			border-top-color: setting(colors, secundary);
		}
	}

	&--info {
		background-color: setting(colors, info);

		&:before {
			border-color: rgba(setting(colors, info), 0);
			border-top-color: setting(colors, info);
		}
	}

	&--warning {
		background-color: setting(colors, warning);

		&:before {
			border-color: rgba(setting(colors, warning), 0);
			border-top-color: setting(colors, warning);
		}
	}

	&--error {
		background-color: setting(colors, error);

		&:before {
			border-color: rgba(setting(colors, error), 0);
			border-top-color: setting(colors, error);
		}
	}
}

/*
Tooltips - Menu

Markup:
<div class="tooltip tooltip--menu {$modifiers}">
	<a href="#" class="tooltip__item"><i class="icon icon--signature--25"></i></a><!--
	--><a href="#" class="tooltip__item"><i class="icon icon--calendar--25"></i></a><!--
	--><a href="#" class="tooltip__item"><i class="icon icon--text--25"></i></a>
</div>

.tooltip--inline - Inline options

Styleguide 23.1
*/

.tooltip--menu {
	padding: 0;
	text-align: center;

	.tooltip__item {
		padding: $b/2;
		display: block;
		border-bottom: 1px solid map-get($tooltip, border);
		width: $b*2;
		height: $b*2;

		&:hover {
			text-decoration: none;
			background-color: map-get($tooltip, background-color-hover);
		}

		&:first-child {
			border-left: 0;
			border-top-left-radius: setting(border-radius);
			border-top-right-radius: setting(border-radius);
		}

		&:last-child {
			border-bottom-left-radius: setting(border-radius);
			border-bottom-right-radius: setting(border-radius);
		}
	}
}

.tooltip--inline {
	white-space: nowrap;
	height: $b*2;

	.tooltip__item {
		display: inline-block;
		border-left: 1px solid map-get($tooltip, border);
		border-bottom: 0;

    &:first-child {
			border-top: 0;
			border-top-left-radius: setting(border-radius);
			border-bottom-left-radius: setting(border-radius);
			border-top-right-radius: 0;
		}

		&:last-child {
			border-bottom-left-radius: 0;
			border-top-right-radius: setting(border-radius);
			border-bottom-right-radius: setting(border-radius);
		}
	}
}

/*
Tooltips - Menu - Small

Markup:
<div class="tooltip tooltip--menu tooltip--bottom tooltip--small {$modifiers}">
	<a href="#" class="tooltip__item"><i class="icon icon--text-down"></i></a><!--
	--><a href="#" class="tooltip__item"><i class="icon icon--text-up"></i></a><!--
	--><a href="#" class="tooltip__item"><i class="icon icon--calendar"></i></a><!--
	--><a href="#" class="tooltip__item"><i class="icon icon--trash"></i></a>
</div>

.tooltip--inline - Inline options

Styleguide 23.1.1
*/

.tooltip--small {
	padding: 0;

	.tooltip__item {
		padding: $b/4;
		width: $b*1.5;
		height: $b*1.5;
	}

	&.tooltip--inline {
		height: $b*1.5;
	}
}
