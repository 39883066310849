#home-box, #home-box-hero {

  &, .module__body {
    // required for phone number country selector div
    overflow: visible;
  }

}
#home-box-hero {
  margin-top: $b * 4;
}
svg.devices {
  max-width: 100%;
}
@include media-query(palm) {
  // force not to use the height of the home-box for mobile
  div[sr-matchheight] {
    height: inherit!important;
  }

  #home-box-hero {
    margin-top: 0;
  }

}

