/*
Modules

Markup:
<div class="module {{modifier_class}}">
	<div class="module__header">
		<span>Lorem ipsum</span>
	</div>
	<div class="module__body">
		<p>Lorem ipsum dolor sit amet</p>
	</div>
	<div class="module__footer">
		<p>Lorem ipsum dolor sit amet</p>
	</div>
</div>

.module--grey - Grey background

Styleguide components.module
*/

.module {
	border: 1px solid setting(colors, border);
	margin-top: $b;
	border-radius: setting(border-radius);

	&__header {
		width: 100%;
		color: #fff;
		background: setting(colors, primary);
		border-top-left-radius: setting(border-radius);
		border-top-right-radius: setting(border-radius);
		padding: $b/2 0 $b/2 0;
		overflow: hidden;
		box-sizing: content-box;
		margin: -1px -1px 0 -1px;
                font-weight: setting(font-weights, normal);
		border: {
			width: 0 1px 0 1px;
			style: solid;
			color: setting(colors, primary);
		};

		&,
		a, strong {
			color: #fff;
		}

		> * {
			margin: 0 12px;
		}
	}

	&__body {
		overflow: hidden;
		padding: $b/2;

		> :first-child {
			margin-top: 0;
		}
	}

        .menu-nav {
                box-shadow: none !important;
                border: none !important;

                .v-list__tile__title {
                    font-weight: bold;
                }
        }

	&__footer {
		border-top: 1px solid setting(colors, border);
		padding: 0 $b $b $b;
	}

	@include media-query(lap-and-up) {
		&__body {
			padding: $b;
		}

		&__header {
			> * {
				margin: 0 24px;
			}
		}
	}

	&--grey {
		background: setting(colors, background-light);
	}

}
