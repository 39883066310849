@mixin font-size($font-size, $line-height: auto) {
	font-size: $font-size * 1px;
	font-size: ($font-size / setting(font-size)) * 1rem;

	@if $line-height == auto {
		line-height: ceil($font-size / setting(line-height)) * (setting(line-height) / $font-size);
	}

	@else {

		@if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
			line-height: $line-height;
		}

		@elseif ($line-height != none and $line-height != false) {
			@warn "'#{$line-height}' is not a valid value for `line-height`."
		}

	}

}

@mixin grid-setup($namespace: "") {
	/**
	* Whole
	*/
	.#{$namespace}one-whole         { width:100%; }


	/**
	* Halves
	*/
	.#{$namespace}one-half          { width:50%; }


	/**
	* Thirds
	*/
	.#{$namespace}one-third         { width:33.333%; }
	.#{$namespace}two-thirds        { width:66.666%; }


	/**
	* Quarters
	*/
	.#{$namespace}one-quarter       { width:25%; }
	.#{$namespace}two-quarters      { @extend .#{$namespace}one-half; }
	.#{$namespace}three-quarters    { width:75%; }


	/**
	* Fifths
	*/
	.#{$namespace}one-fifth         { width:20%; }
	.#{$namespace}two-fifths        { width:40%; }
	.#{$namespace}three-fifths      { width:60%; }
	.#{$namespace}four-fifths       { width:80%; }


	/**
	* Sixths
	*/
	.#{$namespace}one-sixth         { width:16.666%; }
	.#{$namespace}two-sixths        { @extend .#{$namespace}one-third; }
	.#{$namespace}three-sixths      { @extend .#{$namespace}one-half; }
	.#{$namespace}four-sixths       { @extend .#{$namespace}two-thirds; }
	.#{$namespace}five-sixths       { width:83.333%; }

	/**
	* Sevenths
	*/
	.#{$namespace}one-seventh       { width: percentage(1/7); }
	.#{$namespace}two-sevenths      { width: percentage(2/7); }
	.#{$namespace}three-sevenths    { width: percentage(3/7); }
	.#{$namespace}four-sevenths     { width: percentage(4/7); }
	.#{$namespace}five-sevenths     { width: percentage(5/7); }
	.#{$namespace}six-sevenths      { width: percentage(6/7); }


	/**
	* Eighths
	*/
	.#{$namespace}one-eighth        { width:12.5%; }
	.#{$namespace}two-eighths       { @extend .#{$namespace}one-quarter; }
	.#{$namespace}three-eighths     { width:37.5%; }
	.#{$namespace}four-eighths      { @extend .#{$namespace}one-half; }
	.#{$namespace}five-eighths      { width:62.5%; }
	.#{$namespace}six-eighths       { @extend .#{$namespace}three-quarters; }
	.#{$namespace}seven-eighths     { width:87.5%; }


	/**
	* Tenths
	*/
	.#{$namespace}one-tenth         { width:10%; }
	.#{$namespace}two-tenths        { @extend .#{$namespace}one-fifth; }
	.#{$namespace}three-tenths      { width:30%; }
	.#{$namespace}four-tenths       { @extend .#{$namespace}two-fifths; }
	.#{$namespace}five-tenths       { @extend .#{$namespace}one-half; }
	.#{$namespace}six-tenths        { @extend .#{$namespace}three-fifths; }
	.#{$namespace}seven-tenths      { width:70%; }
	.#{$namespace}eight-tenths      { @extend .#{$namespace}four-fifths; }
	.#{$namespace}nine-tenths       { width:90%; }


	/**
	* Twelfths
	*/
	.#{$namespace}one-twelfth       { width:8.333%; }
	.#{$namespace}two-twelfths      { @extend .#{$namespace}one-sixth; }
	.#{$namespace}three-twelfths    { @extend .#{$namespace}one-quarter; }
	.#{$namespace}four-twelfths     { @extend .#{$namespace}one-third; }
	.#{$namespace}five-twelfths     { width:41.666% }
	.#{$namespace}six-twelfths      { @extend .#{$namespace}one-half; }
	.#{$namespace}seven-twelfths    { width:58.333%; }
	.#{$namespace}eight-twelfths    { @extend .#{$namespace}two-thirds; }
	.#{$namespace}nine-twelfths     { @extend .#{$namespace}three-quarters; }
	.#{$namespace}ten-twelfths      { @extend .#{$namespace}five-sixths; }
	.#{$namespace}eleven-twelfths   { width:91.666%; }
}
