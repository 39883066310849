/*
Context

A text alternative for eg. screen reader users.

Markup:
<h1 class="context">Features</h1>
<p>Lorem ipsum dolor sit amet</p>

Styleguide trumps.context
*/

.context {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}
