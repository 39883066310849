.done {
	background: setting(colors, primary);
	color: #fff;
	text-align: center;
	overflow: hidden;
	padding-top: 0;

	h1, h2, h3, h4 {
		color: inherit;
	}

	p {
		color: rgba(255,255,255,0.9);
	}

	a {
		color: #fff;
	}

	hr {
		color: lighten(setting(colors, primary), 10);
		color: rgba(255,255,255,0.15);
	}

	&__inner {
		position: relative;
		height: 100%;
		overflow-x: hidden;
		padding-top: $b*2;
		padding-bottom: $b*2;
		-webkit-overflow-scrolling: touch;
	}

	.icon {
		color: #fff;
	}
}
