/*
Box sizing

No styleguide reference
*/

html {
	box-sizing: border-box;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}
