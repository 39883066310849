
.text {
  &--error {
    color: setting(colors, error);
  }

  &--warning {
    color: setting(colors, warning);
  }

  &--success,
  &--ok {
    color: setting(colors, success);
  }

  &--info {
    color: setting(colors, info);
  }

  &--too-long-block {
    display: inline-block;
    word-break: break-all;
  }
}

.quote {
  margin: $b/2;
  padding: $b/2;
  border-left: 1px solid setting(colors, border);

  .rtl & {
    border-left: inherit;
    border-right: 1px solid setting(colors, border);
  }
}
