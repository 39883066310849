/*
Vertical align

Still using the old fashioned, table-cell hack.

Markup:
<div style="height: 200px;" class="vertical-align {{modifier_class}}">
	<div class="vertical-align__item">Lorem ipsum dolor sit amet</div>
</div>

.vertical-align--bottom - Bottom aligned

Styleguide objects.vertical-align
*/

.vertical-align {
	display: table;
	height: 100%;
	width: 100%;
	table-layout: fixed;
}

.vertical-align__item {
	display: table-cell;
	vertical-align: middle;
	 width:100%;
    height:100%;
}

.vertical-align--bottom {
	.vertical-align__item {
		vertical-align: bottom;
	}
}
