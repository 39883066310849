/*
Clearfix

Contain floats without resorting to using presentational markup.

Markup:
<div class="clearfix">
	<div class="float--left">Floated</div>
	Lorem ipsum dolor sit amet
</div>

No styleguide reference
*/

.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
