/*
Button group

Markup:
<div class="button-group {{modifier_class}}">
	<a href="#" class="button">Me & others</a><!--
	--><a href="#" class="button button--line">Only me</a><!--
	--><a href="#" class="button button--line">Only others</a>
</div>

.button-group--fluid - 100% width

Styleguide components.button-group
*/

.button-group {
	> .button {
		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&:not(:first-child):not(:last-child) {
			border-radius: 0;
		}

		+ .button {
			border-left: 0;
		}
	}
	&--fluid {
		display: table;
		width: 100%;

		> .button {
			display: table-cell;
		}
	}
	&--small {
		margin: $b/2 0;

		.button {
			@extend .button--small;
		}
	}
}
