$layout-gutter: 0.5*$b;
$layout-gutter-desk: 1*$b;

/*
Layouts

A flexible layout system based on the wonderful work of Harry Roberts. Using the layout system is super simple stuff. Each set of layouts comprises of a wrapper to place the grid items in, and then the items themselves. Create a simple two-column layout system where one column (.two-thirds) is twice as big as the other (.one-third). Don't forget to use the <!-- --> fix to remove the whitespace between grid items.

Markup:
<div class="sr-layout {{modifier_class}}">
	<div class="sr-layout__item one-twelfth">
		1/12
	</div><!--
	--><div class="sr-layout__item one-half">
		1/2
	</div><!--
	--><div class="sr-layout__item one-sixth">
		1/6
	</div>
</div>

.sr-layout--flush 		- No gutters.
.sr-layout--rev 		- Reversed rendered order of layout items
.sr-layout--middle 	- Align layout items to the vertical centers of each other.
.sr-layout--bottom 	- Align layout items to the vertical bottoms of each other.
.sr-layout--right 		- Make the layout items fill up from the right hand side.
.sr-layout--center 	- Make the layout items fill up from the center outward.

Styleguide objects.layout
*/

.sr-layout {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-left: -$layout-gutter;

	&__item {
		display: inline-block;
		padding-left: $layout-gutter;
		vertical-align: top;
		width: 100%;
	}

	&--flush {
		margin-left: 0;

		> .sr-layout__item {
			padding-left: 0;
		}
	}

	&--rev {
		direction: rtl;
		text-align: left;

		> .sr-layout__item {
			direction: ltr;
			text-align: left;
		}
	}

	&--middle {
		> .sr-layout__item {
			vertical-align: middle;
		}
	}

	&--bottom {
		> .sr-layout__item {
			vertical-align: bottom;
		}
	}

	&--right {
		text-align: right;

		> .sr-layout__item {
			text-align: left;
		}
	}

  &--flex {
    display: table;
    width: 100%;
    margin-left: 0;
    table-layout: fixed;
  }

	&--center {
		text-align: center;

		> .sr-layout__item {
			text-align: left;
		}
	}

	&--auto {
		> .sr-layout__item {
			width: auto;
		}
	}
}

.rtl .sr-layout {
  &--rev {
    direction: ltr;
    text-align: right;

    > .sr-layout__item {
      direction: rtl;
      text-align: right;
    }
  }
}

@include media-query(lap-and-up) {
	.sr-layout {
		margin-left: -$layout-gutter-desk;
	}

	.sr-layout--flush,
	.sr-layout--flex {
		margin-left: 0;
	}

	.sr-layout__item {
		padding-left: $layout-gutter-desk;
	}
}

.placeholder--25 {
  width:25px;
  height:52px;
  display: inline-block;
}
