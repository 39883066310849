/*
Radiobar

Markup:
<div class="radiobar">
	<label class="radiobar__item">
		<input type="radio" name="options" id="option1" checked="checked">
		<span>Me &amp; others</span>
	</label>
	<label class="radiobar__item">
		<input type="radio" name="options" id="option2" checked="checked">
		<span>Only me</span>
	</label>
	<label class="radiobar__item">
		<input type="radio" name="options" id="option3" checked="checked">
		<span>Only others</span>
	</label>
</div>

Styleguide components.radiobar
*/

.radiobar {
	display: table;
	width: 100%;
	margin: $b/2 0;

	&__item {
		display: table-cell;
		cursor: pointer;
    vertical-align: middle;
    text-align: center;
    border: 1px solid setting(colors, primary);
    color: setting(colors, primary);
    padding: $b/4 $b/4!important;
    border-radius: setting(border-radius);
    transition: border .2s, color .2s, background .2s;
    background-color: #fff;

    &:hover {
      text-decoration: none;
    }

		input {
			display: none;
		}

		span, a {
			display: block;
      height: 100%;
      text-align: center;

      span {
        display: inline-block;
      }

			@include font-size(13);

			&:hover {
				text-decoration: none;
			}
		}

		&:first-child {

			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

      .rtl & {
        border-top-right-radius: setting(border-radius);
        border-bottom-right-radius: setting(border-radius);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

		}

		&:last-child {

			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

      .rtl & {
        border-top-left-radius: setting(border-radius);
        border-bottom-left-radius: setting(border-radius);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

		}

		&:not(:first-child):not(:last-child) {

			border-radius: 0;

		}

		+ .radiobar__item {
			border-left-width: 0;
      .rtl & {
        border-left-width: 1px;
        border-right-width: 0;
      }
		}

		&:hover:not(.disabled) {
			background-color: setting(colors, primary);
			border-color: setting(colors, primary);
      a, span {
        color: #fff;
      }
		}

		&.active {
			background-color: setting(colors, primary);
			border-color: setting(colors, primary);
      a, span {
        color: #fff;
      }
		}
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
	}
}
