/*
Headings

When we define a heading we also define a corresponding class to go with it. This allows us to apply, say, `class="alpha"` to a `h3`; a double-stranded heading hierarchy.

Markup:
<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>

Styleguide base.headings
*/

h1, h2, h3, h4 {
	color: setting(colors, font-dark);
}

h1 {
	font-weight: setting(font-weights, normal);

	@include font-size(22);
}

h2 {
	font-weight: setting(font-weights, bold);

	@include font-size(18);
}

h3 {
	font-weight: setting(font-weights, bold);

	@include font-size(16);
}

h4 {
	font-weight: setting(font-weights, bold);

	@include font-size(14);
}

@include media-query(lap-and-up) {

	h1 {
		@include font-size(28, setting(line-height)*1.5/28);
	}

	h2 {
		@include font-size(18);
	}

}
